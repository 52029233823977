export const encodeKey = (key: string): string => {
    if (Boolean(Number(key))) {
        let a: string = ""
        for (let i = 0; i < key?.length; i++) {
            a += String.fromCharCode(Number(key[i]) + 97)
        }
        return a;
    } else {
        return "no encode";
    }
}

export const decodeKey = (key: string): string => {
    let a:string=""
    for (let i = 0; i < key?.length; i++) {
        a+=String(Number(key.charCodeAt(i) - 97));
    }

    return a;
}