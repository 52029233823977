import { FC } from "react";
import { SelectStudentTheme } from "config/theme/colors";
import useGetAllData from "hooks/useGetAllData";
import { Card, Tag } from "antd";
import { VscDebugBreakpointLog } from "react-icons/vsc";
import { FiExternalLink } from "react-icons/fi";
import { FaLink } from "react-icons/fa";
import { BsDot } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";

type TopicReferensType = Array<{
  name: string,
  order: number,
  start_page: number | null,
  end_page: number | null,
  type: number
}>

const TopicRefarence: FC<{ topicReferens: TopicReferensType }> = ({ topicReferens }): JSX.Element => {

  // let isMobile = /iPhone|Android/i.test(navigator.userAgent);
  // const theme = SelectStudentTheme();
  const types = [
    {
      type: 1,
      title: "Asosiy maqsad va vazifalar, talabalar qanday bilim va ko‘nikmaga ega bo‘lishi kutilmoqda:"
    },
    {
      type: 2,
      title: "O‘qilishi tavsiya etiladigan asosiy adabiyotlar."
    },
    {
      type: 3,
      title: "O‘qilishi tavsiya etiladigan qo‘shimcha adabiyotlar."
    },
    {
      type: 4,
      title: "Muhokama uchun savollar:"
    },
    {
      type: 5,
      title: "Muhokama uchun kazus yoki muammoli savol:"
    },
  ]

  return (
    <Card className="cards m-2">
      {
        types.map((e, i) => (
          <div key={i} className="m-2" >
            <p style={{ fontSize: 16, fontWeight: 500, margin: 0, }}><VscDebugBreakpointLog />&nbsp;<i>{e.title}</i></p>
            {
              topicReferens?.find(a => a?.type === e.type) ? topicReferens?.map((element: any, idx: number) => element?.type === e.type && (
                <p className="mt-1 ms-3" key={idx} >
                  <b>{element.order})</b>&nbsp; <div className="d-inline-block" dangerouslySetInnerHTML={{__html: element.name }} />
                  {element.start_page ? <span style={{ fontWeight: 500 }} >&nbsp;//&nbsp;{element.start_page}-{element.end_page} - sahifalar</span> : null}
                  {element.link ? <a href={element?.link} target="_blank" style={{ fontWeight: 500, textDecoration: "underline" }} ><FaLink style={{marginTop: -3, fontSize: 13, marginLeft: 6}}/>&nbsp;Adabiyot internet havolasi</a> : null}
                </p>
              )) : <Tag color="orange" className="mt-1 ms-3" >Ma'lumot qo'shilamagan</Tag>
            }
          </div>
        ))
      }
    </Card>
  )
}

export default TopicRefarence;