import { Col, Collapse, Divider, Popover, Row, Spin } from "antd";
import { FC, useEffect } from "react"
import { SelectStudentTheme } from '../../config/theme/colors';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { Card, CardBody } from "reactstrap";
import { useRouteMatch, match } from 'react-router';
import useGetAttends from "hooks/useGetAttends";
import { useTranslation } from "react-i18next";
import useGetAllData from "hooks/useGetAllData";
import TitleAndSemestrs from "components/TitleAndSemestr";
import useUrlQueryParams from "hooks/useUrlQueryParams";

const StudentAttendance: FC = (): JSX.Element => {
    const theme = SelectStudentTheme();
    const { t } = useTranslation();
    const match: match<any> | null = useRouteMatch("/std_attendances/:subject_id");
    const { value, writeToUrl } = useUrlQueryParams({})
    const { attends, loading } = useGetAttends();

    const { items: eduSemestrSubject } = useGetAllData({ url: `edu-semestr-subjects?expand=subject,subject.subjectSillabus`, isCall: "auto" });
    const { items: eduSemestr } = useGetAllData({ url: `edu-semestrs?expand=semestr`, isCall: "auto" });

    useEffect(() => {
        if (eduSemestr.length && !value.filter?.semestr_id) {
            const semestrs = eduSemestr.find(e => e.status === 1);
            if(semestrs){
                const [semestr_id, edu_semestr_id] = [semestrs.semestr_id, semestrs.id];
                if (semestr_id) {
                    writeToUrl({ name: "semestr_id", value: semestr_id });
                    writeToUrl({ name: "edu_semestr_id", value: edu_semestr_id });
            }
            }
        }
    }, [eduSemestr])

    return (
        <Spin spinning={loading}>
            <Card>
                <CardBody className="py-2" >
                    <TitleAndSemestrs semesters={eduSemestr} title={"Attendance"} />
                </CardBody>
            </Card>
            <Collapse
                bordered={false}
                defaultActiveKey={[match?.params?.subject_id]}
                className="site-collapse-custom-collapse rounded-3 p-0 mt-3"
            >
                {
                    attends.map((element, i) => {
                        const auditory_time = eduSemestrSubject.find(e => e.subject_id === element?.id)?.subject?.subjectSillabus?.auditory_time
                        return <Collapse.Panel
                            showArrow={false}
                            header={<Row gutter={[12, 0]} className="w-100">
                                <Col md={24} lg={24} xl={24} xxl={8}> <b style={{ color: theme.blue }} >{element.name}</b></Col>
                                <Col md={24} lg={24} xl={24} xxl={16}>
                                    <Row gutter={[12, 12]} >
                                        <Col xl={4} lg={6} md={6} sm={12} xs={12}><span>Jami soat:&nbsp;{eduSemestrSubject.find(e => e.subject_id === element?.id)?.subject?.subjectSillabus?.all_ball_yuklama}</span></Col>
                                        <Col xl={4} lg={6} md={6} sm={12} xs={12}><span>Majburiy soat:&nbsp;{auditory_time}</span></Col>
                                        <Col xl={4} lg={6} md={6} sm={12} xs={12}><span style={{ color: "#c97714" }} >Sababsiz (%): <b>{auditory_time ? Math.floor(1000 * ((element.all_length - element.reason_length) / auditory_time)) / 10 + " %" : null}</b></span></Col>
                                        <Col xl={4} lg={6} md={6} sm={12} xs={12}><span style={{ color: "#00b13b" }} >Sababli: <b>{element.reason_length}</b></span></Col>
                                        <Col xl={4} lg={6} md={6} sm={12} xs={12}><span style={{ color: "#c97714" }} >Sababsiz: <b>{element.all_length - element.reason_length}</b></span></Col>
                                        <Col xl={4} lg={6} md={6} sm={12} xs={12}><span style={{ color: "#1B73E8" }} >Jami: <b>{element.all_length}</b></span></Col>
                                    </Row>
                                </Col>
                            </Row>}
                            key={`${element?.id}`}
                            className="site-collapse-custom-panel rounded-3"
                        >
                            <>
                                {element.attendances[1].length ? <>
                                    <Divider orientation="left" className="mb-1 mt-2" >{element.attendances[1][0].subject_category?.name}</Divider>
                                    <div className="d-f flex-wrap">
                                        {
                                            element?.attendances[1]?.map((item, idx) => (
                                                // <Popover title="Attend" trigger={"click"} content={<div>

                                                // </div>} >

                                                <div className="px-2 rounded-3 d-f mx-1 mb-2" style={{ backgroundColor: `${item.reason ? "#E6F4EA" : "#ffead6"}`, color: item.reason ? "#479360" : "#ca750c" }}>
                                                    <span>{item?.para?.start_time}-{item?.para?.end_time}</span>&nbsp;|&nbsp;
                                                    <span>{item.date}</span>
                                                </div>
                                                // </Popover>
                                            ))
                                        }
                                    </div></> : null}
                                {element.attendances[2].length ? <>
                                    <p className="mb-1 mt-2">{element.attendances[2][0].subject_category?.name}</p>
                                    <div className="d-f flex-wrap">
                                        {
                                            element?.attendances[2]?.map((item, idx) => (
                                                <div className="px-2 rounded-3 d-f mx-1 mb-2" style={{ backgroundColor: `${item.reason ? "#E6F4EA" : "#ffecdb"}`, color: item.reason ? "#479360" : "#ca750c" }} key={idx}>
                                                    <span>{item?.para?.start_time}-{item?.para?.end_time}</span>&nbsp;|&nbsp;
                                                    <span>{item.date}</span>
                                                </div>
                                            ))
                                        }
                                    </div></> : null}
                                {!element.attendances[1].length && !element.attendances[2].length ? <div className="text-center mt-3" >Qoldirilgan darslar mavjud emas</div> : null}
                            </>
                        </Collapse.Panel>
                    })
                }
            </Collapse >
        </Spin >
    )
}

export default StudentAttendance;