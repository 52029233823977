import { useEffect } from "react"
import "./common/style.scss";
import { SelectStudentTheme } from "config/theme/colors";
import React from "react";
import { FC } from "react";
import { Alert, Button, Col, Divider, message, Popconfirm, Row, Spin } from "antd";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { IStudentTimeTable, ITimeOption, ITimeTable, IWeek } from "models/others";
import { IEducationSemester, IPara } from "models/education";
import useDeleteOne from "hooks/useDeleteOne";
import styles from './styles.module.scss';
import { NavLink } from "react-router-dom";
import { SelectSemesterBtn } from "pages/subjects/common/styled";
import { BiArrowFromLeft, BiX } from "react-icons/bi";
import isHasAuthRoute from "utils/isHasRoute";
import instance from "config/_axios";
import { Card, CardBody } from "reactstrap";
import useUrlQueryParams from "hooks/useUrlQueryParams";


const StudentLessonScheduleVariant: FC = (): JSX.Element => {

    const theme: any = SelectStudentTheme();

    const [refetch, setRefetch] = React.useState<boolean>(false);
    const [notSelected, setNotSelected] = React.useState<ITimeTable[]>([]);
    const _delete = useDeleteOne();

    const { value, writeToUrl } = useUrlQueryParams({});

    const para = useGetAllData({ url: `/paras`, isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<IPara>;
    const weeks = useGetAllData({ url: `/weeks`, isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<IWeek>;
    const eduSemesters = useGetAllData({ url: `/edu-semestrs?expand=semestr`, isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<IEducationSemester>;
    const timeOptions = useGetAllData({
        url: `/time-options?expand=selected,timeTables.seminar,timeTables.subject,timeTables.seminar.selected&filter=${JSON.stringify({ edu_semester_id: value.filter.edu_semester_id })}`,
        isCall: "auto",
        perPage: 0,
        refetch: [value.filter.edu_semester_id]
    }) as TypeReturnUseGetAllData<ITimeOption>;
    const timeTables = useGetAllData({
        url: `/student-time-tables?sort=-id&expand=subject,teacher,subjectCategory,building,room,para,week,timeTable.language&semester_id=${value.filter.semester_id}`,
        isCall: 'auto',
        perPage: 0,
        refetch: [_delete.refetch, refetch, value.filter.semester_id]
    }) as TypeReturnUseGetAllData<IStudentTimeTable>;



    React.useEffect(() => {
        if (!value.filter.semester_id) {

            const findActiveEduSemester = eduSemesters.items.find(e => e.status === 1);
            if (findActiveEduSemester) {
                writeToUrl({ name: 'semester_id', value: findActiveEduSemester?.semestr_id });
                writeToUrl({ name: 'edu_semester_id', value: findActiveEduSemester?.id });
            }
        }
    }, [eduSemesters])

    // useEffect(() => {
    //     (
    //         async () => {
    //             const response = await instance({ url: '/edu-semestrs', method: 'GET' })

    //             if (response.data.status === 1) {

    //                 const items = response.data?.data?.items as IEducationSemester[];
    //                 const findActiveSemester = items?.find(element => element.status === 1);
    //                 timeTables.fetch();
    //                 timeOptions.fetch();
    //             }
    //         }
    //     )()
    // }, [_delete.refetch])


    const filterTimeTable = React.useCallback((para_id: number, week_id: number) => {
        if (timeTables.items.length) {

            const filteredTimeTables = timeTables.items.filter(element => element?.week?.id === week_id && element?.para?.id === para_id);

            return filteredTimeTables;

        }
    }, [timeTables])


    React.useEffect(() => {

        if (timeOptions.items.length) {

            let new_object: ITimeTable[] = []

            const findSelected = timeOptions.items.find(element => Number(element.selected) === 1)

            if (findSelected) {

                const onlyLectureTimeTables = findSelected.timeTables?.filter(e => e.subject_category_id === 1 && !Boolean(e.parent_id))


                if (onlyLectureTimeTables?.length) {

                    onlyLectureTimeTables.forEach((element) => {

                        const isSelected = element.seminar.some(e => Number(e.selected) === 1);

                        if (!isSelected) {
                            new_object.push(element);
                        }

                    })

                    setNotSelected(new_object);

                }

            } else (
                message.warning("Dars jadvali hali  tanlanmagan")
            )
        }

    }, [timeOptions.items.length])


    const isSelectedTimeOption = () => {

        if (timeOptions.items.length) {

            const findElement = timeOptions.items.find(element => Number(element.selected) === 1 && Number(value.filter.edu_semester_id) === element.edu_semester_id);

            return findElement
        }

        return null;

    }

    return (
        <Card>
            <CardBody>
                <Spin spinning={timeTables.loading} >
                    <div className="not_user_select px-2">
                        <div className="d-flex justify-content-between">
                            <h6 color={theme.header_text} >Dars jadval</h6>
                            <div>
                                Semester: {eduSemesters.items.length && eduSemesters.items.map((e, i) => {
                                    return <SelectSemesterBtn
                                        key={i}
                                        theme={theme}
                                        style={(value.filter.semester_id ? Number(value.filter.semester_id) === e.semestr_id : e.status === 1) ? { background: "#1890FF", color: "#fff" } : {}}
                                        onClick={() => { writeToUrl({ name: 'semester_id', value: e.semestr_id }); writeToUrl({ name: 'edu_semester_id', value: e.id }); }}
                                    >
                                        {e?.semestr?.name}
                                    </SelectSemesterBtn>
                                })}
                            </div>
                        </div>
                        <Divider className="my-2" ></Divider>
                        <div>
                            {

                                notSelected.map((element) => {
                                    return (
                                        <p>{element.subject?.name}</p>
                                    )
                                })

                            }
                        </div>
                        <div className={styles.notice_time_table}>
                            <Row>
                                <Col xl={18} lg={18} md={16} sm={24} xs={24}>
                                    {
                                        isSelectedTimeOption() ?
                                            <span>Siz tanlagan variant: {isSelectedTimeOption()?.name}</span> :
                                            <span>Dars jadval tanlang</span>
                                    }
                                </Col>
                                <Col xl={6} lg={6} md={8} sm={24} xs={24}>
                                    <NavLink to={`/time_table_variants/${eduSemesters.items.find(e => e.semestr_id === Number(value.filter.semester_id))?.id}`}><Button type="primary" className="w-100">Dars jadvalini tanlash &nbsp;<BiArrowFromLeft size={18} /> </Button></NavLink>
                                </Col>
                            </Row>
                        </div>
                        <Alert type="warning" banner className="my-2" message="O'quv semestiri o'zgarganligini inobatga olgan holda o'tgan semestr ma'lumotini ko'rish uchun tegishli semestrni tanlang!" />
                        <Row gutter={[24, 12]} className="mt-2" >
                            {weeks.items.length ? weeks.items.map((weekItem, weekIndex) => {
                                if (!Boolean(weekItem?.status)) return null
                                return (
                                    <Col key={weekIndex} xl={weekIndex === 4 ? 4 : 5} lg={weekIndex === 4 ? 4 : 5} md={12} sm={12} xs={24} style={{ border: "1px solid #F1F4F9" }} className="px-0" >
                                        {timeTables.items.length ? <div className={styles.time_table_week_name}> {weekItem.name} </div> : null}
                                        <div className="px-1">
                                            {
                                                para.items.length ? para.items.map((paraItem) => {

                                                    const _options = filterTimeTable(paraItem.id, weekItem.id);

                                                    if (!_options?.length) return null;

                                                    return (
                                                        <span style={{ width: 'calc(100% / 5)' }}>
                                                            <div className={styles.time_table_title}>
                                                                <span className={styles.time_lesson} >
                                                                    {paraItem?.name}&nbsp;&nbsp;◷&nbsp;{paraItem?.start_time}-{paraItem?.end_time}
                                                                </span>
                                                            </div>
                                                            {
                                                                <span>
                                                                    {
                                                                        _options?.map((timeTableItem) => {
                                                                            return (
                                                                                <span
                                                                                    key={timeTableItem.id}
                                                                                    className={styles.created_time_table_box}
                                                                                >
                                                                                    <span className={styles.info_text_subject} ><span>{timeTableItem?.building?.name}&nbsp;&nbsp;{timeTableItem?.room?.name?.replace(/\D/g, '')}-xona&nbsp;&nbsp;</span>{timeTableItem?.subject?.name}<br /><i style={{ color: 'blue' }}>{timeTableItem?.subjectCategory?.name} ({timeTableItem?.timeTable?.language?.name})</i></span><br />
                                                                                    <span className={styles.info_text_teacher}>{timeTableItem.teacher?.last_name}&nbsp;{timeTableItem.teacher?.first_name}&nbsp;{String(timeTableItem.teacher?.middle_name).charAt(0)}.</span>
                                                                                    {
                                                                                        isHasAuthRoute('student-time-table_delete') && !timeTableItem.timeTable?.parent_id ?
                                                                                            <Popconfirm
                                                                                                placement="topRight"
                                                                                                title={"O'chirilsinmi ?"}
                                                                                                onConfirm={() => _delete.fetch(`/student-time-tables/${timeTableItem.id}`)}
                                                                                                okText="Ha"
                                                                                                cancelText="Yo'q"
                                                                                            >
                                                                                                <button className={styles.remove_time_table} ><BiX size={16} /></button>
                                                                                            </Popconfirm>
                                                                                            : null
                                                                                    }
                                                                                </span>
                                                                            )
                                                                        })
                                                                    }
                                                                </span>
                                                            }
                                                        </span>
                                                    )
                                                }) : null
                                            }
                                        </div>
                                    </Col>
                                )
                            }) : null}
                        </Row>
                    </div>
                </Spin>
            </CardBody>
        </Card>
    );
}


export default StudentLessonScheduleVariant;



/**
student-time-table_create
student-time-table_delete
student-time-table_index
student-time-table_view
 */