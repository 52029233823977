import { FC, useState } from "react";
import LanguageBtn from "./components/Language";
import { Button, Popover, Skeleton } from "antd";
import { SelectStudentTheme } from "config/theme/colors";
import IconBtnCmponent from './components/IconsBtnComponent';
import { BiCog, BiLogOut, BiWifi, BiWifiOff } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { changeStudentTheme, manageSidebar, TypeInitialStateUi } from "store/ui";
import _logout from "config/_axios/logout";
import { FaBell } from 'react-icons/fa';
import "./styles.scss";
import { StudentHeaderUi } from './styled';
import logo from "assets/images/kiu_logo.png";
import SearchBar from "./components/SearchBar";
import { useAppSelector } from "store/services";
import { useTranslation } from "react-i18next";
import { CgMenuLeft } from "react-icons/cg";

const StudentHeader: FC<{ isSetting: boolean, setIsSetting: (isSetting: boolean) => void }> = ({ isSetting, setIsSetting }): JSX.Element => {

    const ui: TypeInitialStateUi = useAppSelector(state => state.ui) as TypeInitialStateUi;
    const dispatch = useDispatch();
    const{t}=useTranslation();
    const [visible, setVisible] = useState<boolean>(false)
    const theme = SelectStudentTheme()

    const clickBtn = () => {
        dispatch(manageSidebar({}))
    }

    return (
        <StudentHeaderUi theme={theme} >
            <div className="std_header">
                <div className="d-flex justify-content-between">
                    <div className="d-f px-3" style={{ width: 216 }} >
                        <div className="sidebar_avatar me-2">
                            <img src={logo} style={{ color: theme.blue }} alt="" />
                        </div>
                        <h5 style={{ color:/*"#5D48EC"*/ theme.blue, fontWeight: "bold", fontSize: "20px", margin: 0 }}>KIU</h5>
                    </div>
                    <button className="sidebar_btn" onClick={clickBtn}><CgMenuLeft style={{transform: `rotate(180deg)`}} color={theme.blue} size={24} /></button>
                    <SearchBar />
                </div>
                <div className="d-flex text-end">
                    {/* <IconBtnCmponent icon={ ui.isOnline? <BiWifi color={'#389e0d'} size={20} onClick={() => dispatch(changeStudentTheme({}))} />: <BiWifiOff color={'#f5222d'} size={20} />} isBadge={false} /> */}
                    <Popover
                        content={<div style={{ width: "180px" }} ><Skeleton /></div>}
                        trigger="click"
                        visible={visible}
                        onVisibleChange={(visible) => setVisible(visible)}
                    >
                        <IconBtnCmponent icon={<FaBell color={theme.blue} size={18} onClick={() => setVisible(true)} />} isBadge={true} />
                    </Popover>
                    <IconBtnCmponent icon={<BiCog color={theme.blue} /*onClick={() => setIsSetting(true)}*/ size={20} />} isBadge={false} />
                    <LanguageBtn />
                    <Button onClick={_logout} icon={<BiLogOut color={theme.blue} className="me-1" style={{ transform: "rotate(-180deg)" }} size={20} />} className="d-flex align-items-center mx-2" style={{height: 30, borderRadius: '0.25rem'}} >{t("Exit")}</Button>
                </div>
            </div>
        </StudentHeaderUi >
    )
}


export default StudentHeader;