/** @format */

 import styled from "styled-components";

 export const RightSitebarWrapper = styled.div<{ theme: any }>`
  .ant-drawer-body,
  .ant-drawer-wrapper-body {
    box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
    height: calc(100vh - 60px) !important;
  }
  .ant-drawer-mask {
    background-color: transparent;
  }

  /* .avatar {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    position: relative;
    .img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: ${(p) => p.theme.element};
      margin-bottom: 0.75rem;
    }
    .line {
      width: 100%;
      height: 1rem;
      border-radius: 0.25rem;
      background-color: ${(p) => p.theme.element};
      margin: 0.5rem 0;
      &:nth-child(3) {
        width: 70%;
      }
    }
    .close {
      position: absolute;
      top: -1rem;
      right: -0.5rem;
      cursor: pointer;
    }
  } */

  .theme {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
    margin-bottom: 2rem;
    height: 2rem;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.3rem;
      background-color: ${(p) => p.theme.element};
      color: ${(p) => p.theme.text};
      border: 1px solid ${(p) => p.theme.active_element};
      cursor: pointer;
      &:first-child {
        background-color: #333333;
        border: 1px solid #1e2f46;
        color: #6c7883;
      }
    }
  }

  .text_size {
    margin: 0 0.5rem;
    margin-bottom: 3rem;
    .ant-slider-mark-text,
    .ant-slider-mark-text-active {
      font-size: 12px;
    }
  }

  .profile {
    width: 100%;
    height: 2rem;
    border-radius: 0.25rem;
    background-color: ${(p) => p.theme.element};
    margin: 0.5rem 0;
    cursor: pointer;

    color: ${p => p.theme.text};
    padding-left: 1rem;
    display: flex;
    align-items: center;

  }

  .other {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
    height: 200px;
    div {
      background-color: ${(p) => p.theme.element};
      color: ${(p) => p.theme.text};
      /* border: 1px solid ${(p) => p.theme.active_element}; */
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }
`;
