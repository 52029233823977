import { FC } from 'react';
import { SelectStudentTheme } from 'config/theme/colors';
import { StudentContentWrapper } from './styled'
import UnreadMessagesPage from 'pages/notification/UnreadMessagesPage';
import { useAppSelector } from 'store/services';

const StudentContent: FC<{ children: any, isMobile: boolean, isSetting: boolean, setIsSetting: (isSetting: boolean) => void }> = ({ children, isMobile, isSetting, setIsSetting }) => {

    const ui = useAppSelector(state => state.ui);
    const messages = useAppSelector(state => state.data.messages);
    const theme = SelectStudentTheme();
    const _sidebar = useAppSelector(state => state.ui.sidebar);

    return (
        <StudentContentWrapper isMobile={isMobile} theme={theme} ui={ui} sidebar={_sidebar}>   
            {messages.messages.length ? <UnreadMessagesPage /> : children}
        </StudentContentWrapper>
    )
}


export default StudentContent;