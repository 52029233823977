import { AnyAction, configureStore, createListenerMiddleware, ListenerMiddlewareInstance } from "@reduxjs/toolkit"
import uiSlice from './ui';
import SignInSlice from "./auth";
import dataSlice from "./data";
import subjectApi from "./api";
import UserSlice from "./user";
import examSlice from "./exam";


const store = configureStore({
    reducer: {
        ui: uiSlice.reducer,
        auth: SignInSlice.reducer,
        data: dataSlice.reducer,
        user: UserSlice.reducer,
        exam: examSlice.reducer,
        [subjectApi.reducerPath]: subjectApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(subjectApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ReturnType<typeof store.dispatch>;

// store.subscribe(() => {
//     console.log("sdfsd", store.getState())
// })

export default store;