import { FC, useState } from "react"
import { FaArrowLeft } from 'react-icons/fa';
import { useRouteMatch, match } from 'react-router';
import { SelectStudentTheme } from "config/theme/colors";
import { NoticeTimeTableSelect } from ".././common/styled";
import { Col, Row, message, Spin, Tag, Popconfirm, Button } from "antd";
import instance from "config/_axios";
import { useHistory } from "react-router-dom";
import "../../subjects/common/styles.scss";
import ".././common/style.scss";
import { ITimeOption, ITimeTable } from "models/others";
import styles from '../styles.module.scss';
import useGetOneData, { ReturnTypeUseGetOneData } from "hooks/useGetOneData";
import { Card, CardBody } from "reactstrap";
import React from "react";
import { TimeTableVariantItem } from "../components/TimeTableVariantItem";


const TimeTableBySubjects: FC = (): JSX.Element => {

    const theme: any = SelectStudentTheme();
    const history = useHistory();
    const [semenars, setSemenars] = React.useState<Record<string, ITimeTable[]>>({});
    const match: match<any> | null = useRouteMatch("/time_tables_by_subject/:time_option_id");
    const [reFetch, setReFetch] = useState<boolean>(false);

    const { data, loading } = useGetOneData({
        url: `/time-options/${match?.params?.time_option_id}?expand=selected,selectedCount,faculty,room,eduSemester.semestr,timeTables.week,timeTables.subject,timeTables.para,timeTables.subjectCategory,timeTables.teacher,timeTables.room,timeTables.building,timeTables.selected,timeTables.language,timeTables.child.week,timeTables.child.para,timeTables.child.room,timeTables.child.building,timeTables.child.subjectCategory,timeTables.child.language,timeTables.child.teacher,timeTables.child.selectedCount,timeTables.child.selected,timeTables.seminar.week,timeTables.seminar.room,timeTables.seminar.building,timeTables.seminar.subjectCategory,timeTables.seminar.language,timeTables.seminar.teacher,timeTables.seminar.para,timeTables.seminar.selected,timeTables.seminar.selectedCount,timeTables.seminar.child.week,timeTables.seminar.child.room,timeTables.seminar.child.building,timeTables.seminar.child.language,timeTables.seminar.child.subjectCategory,timeTables.seminar.child.teacher,timeTables.seminar.child.para,timeTables.seminar.child.selected`,
        isCall: 'auto',
        refetch: [reFetch]
    }) as ReturnTypeUseGetOneData<ITimeOption>

    const selectTimeTable = async (id: number) => {
        try {
            let formdata = new FormData();
            formdata.append("time_table_id", String(id));
            const response = await instance({ url: "/student-time-tables", method: "POST", data: formdata })

            if (response.data.status === 1) {
                setReFetch(prevState => !prevState)
                message.success(response.data?.message)
            }
        } catch (error: any) {
            message.warning(error?.response?.data?.errors[0]);
        }
    }


    const selectTimeOption = async () => {
        try {
            if (!data.id) {
                message.error("Ma'lumot yuborishda xatolik!")
                return;
            }
            let formdata = new FormData();
            formdata.append("time_option_id", String(data?.id))
            const response = await instance({ url: "/student-time-options?", method: "POST", data: formdata })

            if (response.data.status === 1) {
                setReFetch(prevState => !prevState)
                message.success(response.data?.message)
            }
        } catch (error: any) {
            message.warning(error?.response?.data?.errors[0]);
        }
    }

    React.useEffect(() => {

        if (data.timeTables?.length) {
            const filterOnlysemenarSubjects = data.timeTables.filter(e => !Boolean(e.parent_id) && !Boolean(e.lecture_id) && e.subject_category_id !== 1);

            let new_semenars: Record<string, ITimeTable[]> = {};

            if (filterOnlysemenarSubjects.length) {
                filterOnlysemenarSubjects.forEach((element) => {

                    if (new_semenars.hasOwnProperty(element.subject_id)) {
                        new_semenars[element.subject_id].push(element);
                    } else {
                        new_semenars[element.subject_id] = [element]
                    }
                })

            }

            setSemenars(new_semenars);
        }
    }, [data.timeTables])

    const checkSemenarIsSelected = (semenar: ITimeTable[]) => {

        if (!semenar.length) return false;

        const isSelectded = semenar.some(e => Number(e.selected) === 1);

        return isSelectded;
    }

    return (
        <Card>
            <CardBody>
                <Spin spinning={loading} >
                    <NoticeTimeTableSelect className="mt-2" theme={theme}>
                        <FaArrowLeft onClick={() => history.goBack()} color="#5A8DEE" size={13} /> <span className="mx-2" style={{ fontSize: 13, color: "#000" }}>Dars jadval tanlash</span>
                    </NoticeTimeTableSelect>
                    <p style={{ fontSize: '14px', border: "1px solid #DADCE0", borderRadius: "4px" }} className="py-1 px-2 mt-3">
                        <strong style={{ color: '#C26C03' }} >Eslatma !</strong><br />
                        Quyidagi variantni tanlash orqali bu variantga tegishli barcha ma'ruza dars jadvallarini tanlagan hisoblanasiz. Bunda keltirilgan fanlarning seminar dars jadvalini tanlashingiz mumkin.
                    </p>
                    <div className={styles.selectible_time_table_container}>
                        <Card className={styles.time_table_variant_header}>
                            <CardBody>
                                <div className={styles.time_table_variants}>
                                    <span>Variant - {data?.name}{data?.selected ? <i style={{ color: '#1967D2', fontSize: '12px' }} >( Tanlangan )</i> : null}</span>
                                    <div>
                                        <span className={styles.selected_count} style={Boolean(data.selected) ? { backgroundColor: "#1F92FD" } : { backgroundColor: "#c26c03" }} >Tanlandi: {data?.selectedCount} / {data?.capacity}</span>
                                        {
                                            !Boolean(data.selected) ?
                                                (Number(data.selectedCount) < Number(data.capacity) ?
                                                    <Popconfirm placement="topRight" title={"Tanlamoqchimisiz ?"} onConfirm={selectTimeOption} okText="Ha" cancelText="Yo'q">
                                                        <Button type="primary" size="small" className="ms-3 px-4">Tanlash</Button>
                                                    </Popconfirm> : <Tag color="red">Bu variant uchun joy to'lgan</Tag>) : null
                                        }
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <div className="px-3">
                            {
                                data.timeTables?.length ?
                                    data.timeTables.map((element) => {
                                        if (!Boolean(element.parent_id) && !Boolean(element.lecture_id) && element.subject_category_id !== 1) return null;

                                        const isSelectded = checkSemenarIsSelected(element.seminar);

                                        return (
                                            <Card key={element.id} className="mt-3">
                                                <CardBody>
                                                    <div className={styles.time_table_subjects}>
                                                        <span>{element.subject?.name}&nbsp;&nbsp;<i style={{ color: '#1967D2', fontSize: '12px' }} >(&nbsp;{element?.language?.name})</i></span>
                                                    </div>
                                                    <Row gutter={[12, 12]} className="mt-3" >
                                                        <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                                                            <TimeTableVariantItem isSelected={Boolean(data.selected)} selectTimeTable={selectTimeTable} element={element} />
                                                        </Col>
                                                        {
                                                            element?.seminar?.map((parentElement: any) => {
                                                                return (
                                                                    <Col xl={6} lg={6} md={8} sm={12} xs={24} key={parentElement.id}>
                                                                        <TimeTableVariantItem isSelected={Boolean(data.selected)} selectTimeTable={selectTimeTable} element={parentElement} isSelectedSeminar={isSelectded} />
                                                                    </Col >
                                                                )
                                                            })
                                                        }
                                                    </Row >
                                                </CardBody>
                                            </Card>
                                        )
                                    }) : null
                            }
                            {
                                Object.entries(semenars)?.map(([key, obj]: [string, ITimeTable[]]) => {

                                    if (!obj.length) return null;

                                    const isSelectded = checkSemenarIsSelected(semenars[key]);

                                    return (
                                        <Card key={key} className="mt-3">
                                            <CardBody>
                                                <div className={styles.time_table_subjects}>
                                                    <span>{obj[0]?.subject?.name}&nbsp;&nbsp;<i style={{ color: '#1967D2', fontSize: '12px' }} >(&nbsp;{obj[0]?.language?.name})</i></span>
                                                </div>
                                                <Row gutter={[12, 12]} className="mt-3" >
                                                    {obj.map((semenarItem) => {

                                                        return (
                                                            <Col xl={6} lg={6} md={8} sm={12} xs={24} key={semenarItem.id}>
                                                                <TimeTableVariantItem isSelected={Boolean(data.selected)} selectTimeTable={selectTimeTable} element={semenarItem} isSelectedSeminar={isSelectded} />
                                                            </Col >
                                                        )
                                                    })}
                                                </Row >
                                            </CardBody>
                                        </Card>
                                    )
                                })
                            }

                        </div>
                    </div>
                </Spin >
            </CardBody>
        </Card>
    )
}

export default TimeTableBySubjects;