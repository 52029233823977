import { forwardRef, ForwardRefExoticComponent, RefAttributes, useImperativeHandle, useRef } from "react";
import useGetOneData from "services/useGetOneData";
import "./styles.scss";

const ReferenceFromUniversityUz: ForwardRefExoticComponent<RefAttributes<any>> = forwardRef((props, ref): JSX.Element => {

    const print_page_ref = useRef<HTMLDivElement | null>(null);
    const iframe_ref = useRef<HTMLIFrameElement | null>(null);

    const _data = useGetOneData({url: `students/me?expand=profile,faculty,eduType,eduLangs,eduForm,course`, isCall: 'auto'});


    useImperativeHandle(ref, () => ({
        _print: () => {
            printPage();
        }
    }))

    function printPage() {
        if (iframe_ref?.current) {
            const _iframe = iframe_ref.current;
            _iframe.contentDocument?.open()
            if (print_page_ref?.current) {
                _iframe.contentDocument?.write(print_page_ref?.current?.innerHTML)
            }
            _iframe.contentDocument?.close();
            _iframe.focus();
            _iframe.contentWindow?.print()

        }
    }

    return (
        <div>
            <iframe ref={iframe_ref} style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe>
            <div ref={print_page_ref} className="page_information_papper" style={{ backgroundColor: "#fff" }} >
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="50%">
                                <div style={{ width: "max-content" }}>
                                    <div style={{ width: "max-content", textAlign: "center" }}>
                                        <b style={{ color: "#002060", fontSize: "13px" }}>TOSHKENT DAVLAT YURIDIK<br />UNIVERSITETI</b><br />
                                        <span style={{ color: "#002060", fontSize: "13px" }}>
                                            TASHKENT STATE UNIVERSITY OF LAW<br />STUDENT SERVICES CENTER</span><br />
                                        <b style={{ color: "#002060", fontSize: "13px" }}> TALABALARGA XIZMAT<br />
                                            KO‘RSATISH MARKAZI</b><br />
                                    </div>
                                    <hr style={{ width: "100%", backgroundColor: "#1F4E79", height: "5px", border: "none" }} className="p-0 m-0" />
                                    <hr style={{ width: "100%", backgroundColor: "#1F4E79", height: "1px", marginTop: "2px", border: "none" }} className="p-0 mb-0" />
                                    <div style={{ width: "max-content", textAlign: "center" }}>
                                        <span style={{ color: "#002060", fontSize: "11px", lineHeight: "12px", display: "block", marginTop: "5px" }}>100047, Toshkent shahri, Sayilgoh ko‘chasi, 35-uy<br />
                                            tel.: (71) 233-66-36, www.kiu.uz, info-ssc@kiu.uz</span>
                                        <span style={{ color: "#000000", letterSpacing: "0", fontSize: "11px", lineHeight: "16px", display: "block", paddingLeft: "16px", marginTop: '4px', textAlign: 'left' }}>
                                            № ______________<br />
                                            “____” _________20__ y.
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td width="50%" align="center">
                                <div>
                                    <b style={{ color: "#000000", fontSize: "13px" }}>
                                        O‘QISH JOYIDAN<br />
                                        MA’LUMOTNOMA</b><br />
                                    <b style={{ fontSize: "11px", color: "#000000" }}>Letter of Confirmation</b>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ fontSize: "13px", color: "#000", marginTop: "20px" }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{_data?.data?.profile?.last_name} {_data?.data?.profile?.first_name} {_data?.data?.profile?.middle_name} Toshkent davlat yuridik universiteti
                    bakalavriatning {_data.data?.eduForm?.name} ta’lim shaklida {_data?.data?.faculty?.name} fakultetining {_data?.data?.course?.name}-kursida {_data?.data?.is_contract ? "kontrakt" : "grant"} asosida o‘qiydi.<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Toshkent davlat yuridik universiteti {_data?.data?.eduType?.name}iati {_data.data?.eduForm?.name} taʼlim shaklining davomiyligi toʻrt oʻquv yilini tashkil etadi.<br />
                    Ma’lumotnoma so‘ralgan joyga taqdim etish uchun berildi.
                </div>
                <div style={{ marginTop: '16px', display: 'flex' }}>
                    <b style={{ color: "#000", fontSize: "13px", paddingLeft: "40px", width: '50%', display: 'inline-block' }}>Markaz direktori	 v.b.</b>
                    <b style={{ color: "#000", fontSize: "13px", textAlign: "end", width: '50%', display: 'inline-block', paddingRight: "15px" }}>S.Mamanazarov</b>
                </div>
            </div>
        </div >
    )

})


export default ReferenceFromUniversityUz;