/** @format */

import styled from "styled-components";

export const StdContent = styled.div<{ theme: any, sidebar: string }>`
  background-color: ${(p) => p.theme.card};
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: white;
  margin-top: 60px;
  transition: all 0.1s;
  position: relative;
`;
