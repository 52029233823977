import { Button, Col, Row } from "antd";
import CustomBreadcrumb from "components/Breadcrumb";
import { SelectStudentTheme } from "config/theme/colors";
import moment from "moment";
import { Wrapper } from "pages/styles/style";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Card, CardBody } from "reactstrap";
import { getAllExams } from "services";
import { examType } from "../types";
import { useRouteMatch, match } from "react-router";

const _breadcrump = [
    {
        name: "Home",
        path: "/",
    },
    {
        name: "Subjects",
        path: "/std_exam_subject",
    },
    {
        name: "Exams",
        path: '',
    }
];

const ExamsBySubject: FC = (): JSX.Element => {
    const { t } = useTranslation();
    const history = useHistory()
    const theme = SelectStudentTheme();
    const [exams, setExams] = useState<Array<examType>>([])
    const match: match<any> | null = useRouteMatch("/exams_by_subject/:subject_id");

    useEffect(() => {
        (
            async () => {
                const resp = await getAllExams(undefined, undefined, `filter={"subject_id":${match?.params?.subject_id}}&expand=examType`);
                setExams(resp?.items)
            }
        )()
    }, [])


    return (
        <Card>
            <CardBody>
                <CustomBreadcrumb arr={_breadcrump} />
                <Row className="p-2 px-0">
                    <Col span={24}>
                        <Wrapper theme={theme}>
                            <Table className="table">
                                <Thead>
                                    <Tr>
                                        <Th style={{ width: "3%" }}>№</Th>
                                        <Th>{t("Name")}</Th>
                                        <Th>{t("Exam type")}</Th>
                                        <Th>{t("Start time")}</Th>
                                        <Th>{t("Dead line")}</Th>
                                        <Th>{t("Duration")}</Th>
                                        <Th>{t("Max ball")}</Th>
                                        <Th>{t("Natija")}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        exams?.map((item: examType, i: number) =>
                                            <Tr className="hover table-row-cursor-pointer" key={i}>
                                                <Td>{i + 1}</Td>
                                                <Td>{item?.name}</Td>
                                                <Td>{item?.examType?.name}</Td>
                                                <Td>{item?.start}</Td>
                                                <Td>{item?.finish}</Td>
                                                <Td>{item?.duration ? moment.utc(Number(item?.duration) * 1000).format("HH:mm") : null}</Td>
                                                <Td>{item?.max_ball}</Td>
                                                <Td><Button
                                                    onClick={() => {
                                                        item?.status === 4 && history.push(`/student-exams/${match?.params?.subject_id}/exam-result/${item?.id}`)
                                                    }}
                                                    type={
                                                        item?.status === 4 ?
                                                            "primary" : "text"
                                                    }>
                                                    <span style={{
                                                        color:
                                                            item?.status === 4 ? "white" : theme.text, zIndex: 1
                                                    }}>
                                                        {t("View")}
                                                    </span>
                                                </Button>
                                                </Td>
                                            </Tr>
                                        )
                                    }
                                </Tbody>
                            </Table>
                        </Wrapper>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default ExamsBySubject;