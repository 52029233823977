import { Alert, Button, message, Spin } from "antd";
import { EmptyData } from "components/EmptyTable";
import { SelectStudentTheme } from "config/theme/colors";
import instance from "config/_axios";
import { Title } from "pages/styles/style";
import { FC, useEffect, useState } from "react"
import { CgDetailsMore } from "react-icons/cg";
import { NavLink } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { getAllEduSemesters, getAllEduSemesterSubjects } from "services";
import styled from "styled-components";

export type TypeTopics = Array<{
  id: number,
  name: string,
  description: string
}>

const SubjectSelection: FC = (): JSX.Element => {
  const theme = SelectStudentTheme()
  const [subjects, setSubjects] = useState<Array<any>>([])
  const [loading, setLoading] = useState<boolean>(false);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [selectedSubjectId, setSelectedSubjectId] = useState<number | null>();
  // const [isSubjectSelection, setIsSubjectSelection] = useState<number>()

  useEffect(() => {
    (
      async () => {
        try {
          setLoading(true);
          const semester = await getAllEduSemesters('all', 1, 'expand=semestr');
          const active_semester = semester.items.filter((e: any) => e?.status === 1)[0]

          const res = await getAllEduSemesterSubjects('all', 1, `expand=subject,subject.topics,subjectType,selection,sillabus&filter='{"edu_semestr_id":${active_semester?.id}}'`);
          setSubjects(res?.items?.filter((e: any) => e?.subjectType?.id === 6))

          res.items.forEach((e: any) => {
            if (e?.selection) {
              setIsSave(true)
              setSelectedSubjectId(e?.id);
            }
          })
          setLoading(false);
        } catch (error: any) { }
      }
    )()
  }, [])

  const viewSubjectTopic = (id: number) => {
    let topics: TypeTopics = []
    const stopics = subjects?.find((e: any) => e?.id === id)?.subject?.topics;

    stopics?.forEach((e: any) => {
      topics.push({ id: e?.id, name: e?.name, description: e?.description })
    })

    localStorage.setItem('topics', JSON.stringify(topics))
  }

  const onSubmit = async () => {
    if (selectedSubjectId) {
      try {
        const data = new FormData();
        data.append("edu_semestr_subject_id", String(selectedSubjectId))
        const response = await instance({ url: "/student-subject-selections", method: "POST", data })

        if (response.data.status === 1) {
          message.success(response.data?.message)
        }
      } catch (error) { }
      setIsSave(true)
    } else {
      message.warning("Iltimos fanni tanlang")
    }
  }

  return (
    <Card>
      <CardBody>
        <Spin spinning={loading} >
          <SelectSubjectWrapper theme={theme}>
            <Title color={theme.header_text} >Fan tanlash</Title>
            <Alert type="warning" description="Bu fanlar 'tanlov fan' hamda bir marotaba tanlash imkoni mavjud! Eng ko'p ovoz to'plagan fan(lar) bo'yicha 2022-2023 o'quv yili tegishli yo'nalishlar uchun o'qitilishi belgilanadi." />
            {
              subjects?.length ? <>
                <div className="mt-3">
                  {
                    subjects.length ? subjects?.map((element: any, i: number) => {
                      if (element.subject?.status === 0) return;
                      return (
                        <div key={i} className="subject-box" onClick={() => !isSave && setSelectedSubjectId(element?.id)} style={{ background: selectedSubjectId === element?.id ? "#c5fc8e" : "" }} >
                          <div style={{ fontSize: 17, fontWeight: 500 }} >{element?.subject?.name}</div>
                          <NavLink to={{ pathname: `/subject_topic/${element?.subject?.name}` }} onClick={() => viewSubjectTopic(element?.id)} >Fan mavzulari</NavLink>
                          {/* <Button onClick={() => viewSubjectTopic(element?.id)} >Fan mavzulari</Button> */}
                        </div>
                      )
                    }) : null
                  }
                </div>
                <div className="d-f justify-content-end mt-3">
                  <Button size="large" type="primary" onClick={onSubmit} disabled={isSave} > Tanlovni saqlash </Button>
                </div>
              </> : <EmptyData data={subjects} loading={loading} description={"Sizda tanlov fanlar yo'q"} />
            }
          </SelectSubjectWrapper>
        </Spin>
      </CardBody>
    </Card>
  )
}

export default SubjectSelection;

export const SelectSubjectWrapper = styled.div<{ theme: any }>`
  .header-subject {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.theme.active_element};
    color: ${(props) => props.theme.header_text};
    font-weight: 500;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    border-radius: .5rem .5rem .25rem .25rem;
  }
  .subject-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: ${(props) => props.theme.element};
    color: ${(props) => props.theme.text};
    width: 100%;
    min-height: 3.2rem;
    border-radius: 0.3rem;
    cursor: pointer;
    &:hover{
        background-color: ${(props) => props.theme.active_element};
    }
  }
`;