import styled from "styled-components";


export const SidebarFooterUi = styled.div<{theme: any}>`

    width:100%;
    height:30px;
    position:absolute;
    bottom:0;
    left:0;
    /* background: rgb(25,39,59);
    background: linear-gradient(90deg, rgba(25,39,59,1) 0%, rgba(27,41,62,1) 50%, rgba(25,39,59,1) 100%); */
    background-color: ${props => props.theme.element};
    text-align:center;
    border-radius: 0 0 6px 6px;

    & > span {
        color:#6C7883;
        font-size:13px;
        vertical-align:middle;
    }

`