import styled, { css } from "styled-components";

export const StudentContentWrapper = styled.div<{
  isMobile: boolean;
  theme: any;
  ui: any;
  sidebar: string
}>`
  /* position: relative; */
  overflow: hidden;
  margin-left: ${p=> p.sidebar === 'lg' ? 260 : 0}px;

  ${(props) =>
    props.isMobile
    ? css`
          background-color: ${props.theme.card};
          min-height: calc(100vh - 60px);
          /* margin: 1rem auto; */
          /* padding: 0.5rem 1rem;
          padding-top: 1rem; */
          color: ${props.theme.text};
          position: relative;
          width: 100%;
          `
      : css`
          background-color: ${props.theme.card};
          width: calc(100% - ${props.sidebar === 'lg' ? 260 : 0}px);
          min-height: calc(100vh - 60px);
          padding: 0.5rem 1rem;
          padding-top: 1rem;
          color: ${props.theme.text};
          transition: all 0.1s ease-in-out;
        `}
`;
