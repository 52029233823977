import React, { ChangeEvent, KeyboardEvent, KeyboardEventHandler } from "react";
import { Button, Col, Input, message, Row, Spin, Typography } from "antd";
import { useRouteMatch, match } from "react-router";
import { Card, CardBody } from "reactstrap";
import { useAppDispatch, useAppSelector } from "store/services";
import { GetExamInfo, GetExamQuestion } from "../service.requests";
import ExamSurvey from "./exam_survey";
import { FaArrowLeft, FaBook, FaExclamationTriangle, FaRegDotCircle } from "react-icons/fa";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomBreadcrumb from "components/Breadcrumb";
import { NoticeTimeTableSelect } from "pages/lesson_schedule/common/styled";
import { SelectStudentTheme } from "config/theme/colors";




const ExamInfo = () => {

    const match: match<any> | null = useRouteMatch("/exam/info/:exam_id");
    const dispatch: any = useAppDispatch();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const theme: any = SelectStudentTheme();
    const { isLoading, data } = useAppSelector(state => state.exam.exam_info);

    const [password, setPassword] = React.useState<string>('');




    React.useEffect(() => {
        dispatch(GetExamInfo({ exam_id: match?.params.exam_id }));
    }, [i18n.language])


    // if (!Boolean(data.surveyStatus)) {
    //     return <ExamSurvey />;
    // }



    function getQuestions() {
        if (data.hasAccess === 0) {
            if(password && data.is_protected)
                dispatch(GetExamQuestion({ password, history, exam_id: match?.params.exam_id }))
            if(!data.is_protected)
                dispatch(GetExamQuestion({ password: "password", history, exam_id: match?.params.exam_id }))
        } else {
            if (!password) {
                message.warning("Imtihon topshirish uchun parol topilmadi!");
            } else {
                message.warning("Imtihon topshirish uchun ruxsat yo'q!");
            }
        }
    }

    return (
        <Spin spinning={isLoading}>
            <Card style={{width: 540, margin: "0 auto"}} >
                <CardBody>
                    <NoticeTimeTableSelect className="mt-2" theme={theme} >
                        <FaArrowLeft onClick={() => history.goBack()} color="#5A8DEE" size={13} /> <span className="mx-2" style={{ fontSize: 13, color: "#000" }}>Imtihon haqida ma'lumot</span>
                    </NoticeTimeTableSelect>

                    {/* <Row gutter={[12, 24]} className="waring_contract py-3 mt-3 mx-1">
                        <Col xl={4} lg={6} md={6} sm={24} xs={24} className="text-center"><FaExclamationTriangle size={60} color="#FF0000" /></Col>
                        <Col xl={20} lg={18} md={18} sm={24} xs={24}>
                            <strong>
                                Diqqat ogohlantirish!!!
                                Diqqat!!!
                            </strong>
                            <p>
                                Hurmatli talaba 30.01.2023 sanadan to'lov kontrakt shartnoma summasining 50% dan kam miqdorda to'lov qilgan talabalar yakuniy imtihon topshirishga ruxsat berilmaydi.<br />
                                Tizim avtomatik bloklanadi !
                                Hurmatli talaba 10.02.2023 sanadan to'lov kontrakt shartnoma summasining 50% dan kam miqdorda to'lov qilgan talabalar yakuniy imtihonga qo'yilmaydi.
                                Tizim tomonidan avtomatik bloklanadi.
                            </p>
                        </Col>
                    </Row> */}
                    <Row gutter={[12, 12]} className="mt-4">
                        <Col span={24}>
                            <div className="exam_info_data">
                                <p>
                                    <span><FaRegDotCircle />Fan nomi</span>
                                    <span>{data.subject?.name}</span>
                                </p>
                                <p>
                                    <span><FaRegDotCircle className="me-2" />Imtihon</span>
                                    <span>{data.name}</span>
                                </p>
                                <p>
                                    <span><FaRegDotCircle />Imtihon ma'lumotlari</span>
                                    <span>Savollar soni: <strong>{Object.keys(JSON.parse(data?.question_count_by_type_with_ball ?? "{}")).length}</strong></span>
                                    <span>Maksimal ball: <strong>{data.max_ball}</strong></span>
                                    <span>Davomiyligi: <strong>{data?.duration ? moment.utc(data?.duration * 1000).format("HH:mm") : "Mavjud emas"}</strong></span>
                                    <span>Imtihon turi: <strong>{data.examType?.name}</strong></span>
                                </p>
                            </div>
                        </Col>
                        <Col span={24}>
                            {
                                data.hasAccess === 0 ?
                                data.is_protected ?
                                    <div className="exam_password_box">
                                        <span>Imtihon himoyalangan. Parolni kiriting.</span>
                                        <Row gutter={[12, 12]} className="mt-5">
                                            <Col xl={16}>
                                                <Input
                                                    placeholder="KODNI KIRITING"
                                                    type="password"
                                                    autoComplete="off"
                                                    onChange={(event: ChangeEvent<HTMLInputElement>) => { setPassword(event.target.value) }}
                                                    onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                                                        if (event.key === "Enter" && password) {
                                                            getQuestions()
                                                        }
                                                    }} />
                                            </Col>
                                            <Col xl={8}>
                                                <Button type="primary" className="w-100" disabled={!password} onClick={getQuestions} >Yuborish</Button>
                                            </Col>
                                            {/* <Col xl={24}>
                                                <NavLink to={`/exam/student/answers/${data.id}`} className="mt-2 d-block">Imtihon uchun yozilgan javoblar</NavLink>
                                            </Col> */}
                                        </Row>
                                    </div> :
                            <div className="exam_password_box">
                            {/* <span>Imtihon boshlash</span> */}
                            <Row gutter={[12, 12]}>
                                {/* <Col xl={16}>
                                    <Input
                                        placeholder="KODNI KIRITING"
                                        type="password"
                                        autoComplete="off"
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => { setPassword(event.target.value) }}
                                        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                                            if (event.key === "Enter" && password) {
                                                getQuestions()
                                            }
                                        }} />
                                </Col> */}
                                <Col span={24}>
                                    <Button type="primary" block className="w-100" onClick={getQuestions} >Imtohonni boshlash</Button>
                                </Col>
                                {/* <Col span={24}>
                                    <NavLink to={`/exam/student/answers/${data.id}`} className="mt-2 d-block">Imtihon uchun yozilgan javoblar</NavLink>
                                </Col> */}
                            </Row>
                        </div>
                                    :
                                    <div className="exma_info_warning text-center">
                                        <FaExclamationTriangle size={60} color="#FC1313" />
                                        <p>Imtihon topshirish cheklangan!</p>
                                        {
                                            data.studentSubjectRestrict?.description ?
                                                <span><Typography.Text type="warning">Izoh: </Typography.Text> {data.studentSubjectRestrict?.description}</span> : null
                                        }
                                        {/* <NavLink to={`/std_attendances/${data.subject?.id}`}>Qoldirilgan darslar</NavLink> */}
                                    </div>
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Spin>
    )

}





export default ExamInfo;