import { SelectStudentTheme } from "config/theme/colors";
import React, { FC } from "react";
import { useRouteMatch, match } from 'react-router';
import { NavLink, useHistory } from "react-router-dom";
import { Button, Col, Divider, message, Popconfirm, Row, Spin, Typography } from "antd";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { ITimeOption } from "models/others";
import useDeleteOne from "hooks/useDeleteOne";
import styles from '../styles.module.scss';
import "../common/style.scss";
import { BiBookOpen, BiTime, BiUserVoice } from "react-icons/bi";
import { Card, CardBody } from "reactstrap";
import { NoticeTimeTableSelect } from "../common/styled";
import { FaArrowLeft } from "react-icons/fa";
import instance from "config/_axios";

const { Text } = Typography;

const TimeTableVariants: FC = (): JSX.Element => {

    const theme: any = SelectStudentTheme();
    const match: match<any> | null = useRouteMatch("/time_table_variants/:edu_semester_id");
    const _delete = useDeleteOne();
    const history = useHistory();
    const [reFetch, setReFetch] = React.useState<boolean>(false);
    const timeOptions = useGetAllData({
        url: `/time-options?expand=selected,selectedCount,faculty,eduSemester.semestr,timeTables.week,timeTables.para,timeTables.subjectCategory,timeTables.teacher,timeTables.subject&filter=${JSON.stringify({ edu_semester_id: match?.params?.edu_semester_id })}`,
        perPage: 0,
        isCall: 'auto',
        refetch: [reFetch],
    }) as TypeReturnUseGetAllData<ITimeOption>;




    const selectTimeOption = async (id: number) => {
        try {
            if (!id) {
                message.error("Ma'lumot yuborishda xatolik!")
                return;
            }

            let formdata = new FormData();
            formdata.append("time_option_id", String(id))
            const response = await instance({ url: "/student-time-options?", method: "POST", data: formdata })

            if (response.data.status === 1) {
                setReFetch(prevState => !prevState)
                message.success(response.data?.message)
            }
        } catch (error: any) {
            message.warning(error?.response?.data?.errors[0]);
        }
    }

    const isSelectedOption = () => {
        const isSelectded = timeOptions.items.some(e => e.selected === 1)
        return isSelectded;
    }

    return (
        <Card>
            <CardBody>
                <Spin spinning={timeOptions.loading} >
                    <div className="not_user_select px-2">
                        <div className="d-flex justify-content-between">
                            <h6 color={theme.header_text} >Dars jadval</h6>
                        </div>
                        <NoticeTimeTableSelect className="mt-2" theme={theme}>
                            <FaArrowLeft onClick={() => history.goBack()} color="#5A8DEE" size={13} /> <span className="mx-2" style={{ fontSize: 13, color: "#000" }}>Dars jadval tanlash</span>
                        </NoticeTimeTableSelect>
                        <Divider className="my-2" ></Divider>
                        <div style={{ backgroundColor: "#EFF2F4", padding: "12px" }} className="px-10" >
                            <Row gutter={[12, 12]}>
                                <Col xl={24} xs={24}>
                                    <Card>
                                        <CardBody>
                                            <p style={{ fontSize: '14px' }}>
                                                <strong style={{ color: '#C26C03' }} >Eslatma ! Tanlash bir marotaba.</strong><br />
                                                Quyida keltirilgan variantlarda dars jadvallari mavjud bo'lib, ular bilan tanishib chiqish uchun
                                                variantdagi ( <strong style={{ color: '#0D6EFD' }} >VARIANT - </strong> ) so'ziningning ustiga bosing  va undagi mavjud dars jadvallari bilan tanishib chiqing.
                                                Tanishganingizdan so'ng bu variantlardan birini tanlashingiz mumkin
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                {
                                    timeOptions.items?.map((element) => {
                                        return (
                                            <Col key={element.id} xl={6} lg={6} md={12} sm={12} xs={24}>
                                                <span className={styles.link_variant_card}>
                                                    <Card>
                                                        <CardBody style={Boolean(element.selected) ? { border: "1px solid #1F92FD", borderRadius: "4px" } : {}}>
                                                            <div className={styles.time_table_variants}>
                                                                <NavLink to={`/time_tables_by_subject/${element.id}`}>Variant - {element?.name}</NavLink>
                                                                <div>
                                                                    <span className={styles.selected_count} style={Boolean(element.selected) ? { backgroundColor: "#1F92FD" } : { backgroundColor: "#c26c03" }} >Tanlandi: {element?.selectedCount} / {element?.capacity}</span>
                                                                    {
                                                                        !isSelectedOption() ?
                                                                            (Number(element.selectedCount) < Number(element.capacity) ?
                                                                                <Popconfirm placement="topRight" title={"Tanlamoqchimisiz ?"} onConfirm={() => selectTimeOption(element.id)} okText="Ha" cancelText="Yo'q">
                                                                                    <Button type="primary" size="small">Tanlash</Button>
                                                                                </Popconfirm> : null) : null
                                                                    }
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            {
                                                                element.timeTables?.map((childElement) => {
                                                                    return (
                                                                        <div key={childElement.id} style={{ cursor: 'pointer' }} className={styles.time_table_select_card} onClick={() => history.push(`/time_tables_by_subject/${element.id}`)} >
                                                                            <span><BiTime />{childElement.week?.name} / {childElement.para?.start_time}-{childElement.para?.end_time} / {childElement.subjectCategory?.name}</span>
                                                                            <span><BiBookOpen />{childElement.subject?.name}</span>
                                                                            <span><BiUserVoice />{childElement.teacher?.last_name}&nbsp;{childElement.teacher?.first_name}.&nbsp;{String(childElement.teacher?.middle_name).charAt(0).toUpperCase()}</span>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </CardBody>
                                                    </Card>
                                                </span>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </div>
                </Spin>
            </CardBody>
        </Card>
    );
}


export default TimeTableVariants;



/**
student-time-table_create
student-time-table_delete
student-time-table_index
student-time-table_view
 */