import { Col, Row } from "antd";
import { FC, useEffect, useState } from "react"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { Title, Wrapper } from "../styles/style";
import { SelectSemesterBtn } from "../subjects/common/styled";
import { eduSemestrType, examType } from "./types";
import './style.scss'
import { SelectStudentTheme } from "config/theme/colors";
import { getAllEduSemesters, getAllExams } from "services";
import { EmptyData } from "components/EmptyTable";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";

const StudentExams: FC = (): JSX.Element => {
    const { t } = useTranslation();
    const history = useHistory();
    const theme = SelectStudentTheme();
    let isMobile = /iPhone|Android/i.test(navigator.userAgent);
    const [loading, setLoading] = useState<boolean>(false);
    const [eduSemestrs, setEduSemestrs] = useState<Array<eduSemestrType>>();
    const [exams, setExams] = useState<Array<{ id: number, name: string, edu_semestr_id: number, exam: Array<examType>, }>>();
    const [examsAll, setExamsAll] = useState<Array<{ id: number, name: string, edu_semestr_id: number, exam: Array<examType>, }>>();
    const [activeSemestr, setActiveSemestr] = useState<number>(-1);

    useEffect(() => {
        (
            async () => {
                setLoading(true)
                let examsList: Array<{ id: number, name: string, edu_semestr_id: number, exam: Array<examType> }> = []
                const responseExams = await getAllExams(0, 1, `expand=examType,eduSemestrSubject.subject`)
                if (Array.isArray(responseExams?.items)) {
                    responseExams?.items?.map((item: examType) => {
                        let index = examsList?.findIndex((itemIn: { id: number, name: string, exam: Array<examType> }) => itemIn.id === item?.edu_semestr_subject_id)
                        if (index > -1) {
                            examsList[index] = {
                                id: item?.edu_semestr_subject_id,
                                edu_semestr_id: item?.eduSemestrSubject?.edu_semestr_id,
                                name: item?.eduSemestrSubject?.subject?.name,
                                exam: [...examsList[index]?.exam, item]
                            }
                        } else {
                            examsList.push({
                                id: item?.edu_semestr_subject_id,
                                edu_semestr_id: item?.eduSemestrSubject?.edu_semestr_id,
                                name: item?.eduSemestrSubject?.subject?.name,
                                exam: [item]
                            })
                        }
                    })
                }
                setExamsAll(examsList)
                setLoading(false)
            }
        )()
    }, [])

    useEffect(() => {
        (
            async () => {
                const eduSemestrsResp = await getAllEduSemesters();
                setEduSemestrs(eduSemestrsResp?.items);
                Array.isArray(eduSemestrsResp?.items) && eduSemestrsResp?.items?.map((item: eduSemestrType) => {
                    if (item?.status === 1) {
                        setActiveSemestr(Number(item?.id))
                    }
                })
            }
        )()
    }, [])

    useEffect(() => {
        if (activeSemestr !== -1) {
            setExams(
                examsAll?.filter((item: { id: number, name: string, edu_semestr_id: number, exam: Array<examType> }) => Number(item?.edu_semestr_id) === Number(activeSemestr))
            )
        }
    }, [activeSemestr, examsAll])

    const getSemestrSubjects = async (id: number) => {
        setActiveSemestr(id)
    }

    const setStatusSemestrLocal = (id: number) => {
        let a: Array<eduSemestrType> = [];
        eduSemestrs?.map((item: eduSemestrType) => {
            if (item?.id === id) {
                a.push({ ...item, status: 1 })
            } else {
                a.push({
                    ...item, status: 0
                })
            }
        })
        setEduSemestrs(a)
    }

    return (
        // isMobile ?
        //     <div className="text-center">
        //         <Result
        //             status="warning"
        //             title="Your device does not support."
        //         />
        //     </div>
        //     :
        <Card>
            <CardBody>
                <Wrapper theme={theme}>
                    <Row className="p-2 px-0">
                        <Col sm={24} md={24} lg={12} xl={12}>
                            <Title color={theme.header_text} >{t("Exams")}</Title>
                        </Col>
                        <Col sm={24} md={24} lg={12} xl={12} className="d-flex flex-wrap align-items-center justify-content-end">
                            <strong>
                                {t("Semestrs")}: &nbsp;
                            </strong>
                            {Array.isArray(eduSemestrs) && eduSemestrs.length && eduSemestrs.map((e: eduSemestrType, i) => {
                                return <SelectSemesterBtn className="m-1" key={i} onClick={() => {
                                    getSemestrSubjects(Number(e?.id))
                                    setStatusSemestrLocal(Number(e?.id))
                                }} style={{ backgroundColor: e?.status === 1 ? "#1890ff" : "", color: e?.status === 1 ? "white" : "" }} theme={theme}>{e?.semestr_id}</SelectSemesterBtn>
                            })}
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24} >
                            {loading ? <div className="loader-line"></div> : <div style={{ height: '3px' }}></div>}
                            <Wrapper theme={theme}>
                                <Table className="table">
                                    <Thead>
                                        <Tr>
                                            <Th style={{ width: "3%" }}>№</Th>
                                            <Th>{t("Subject name")}</Th>
                                            <Th style={{ width: "200px" }}>{t("Exams count")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            exams ? exams?.map((element: { id: number, name: string, exam: Array<examType>, }, i: number) => (
                                                <Tr onClick={() => {
                                                    history.push(`/student-exams/${element.id}`)
                                                }} className="table-row-cursor-pointer hover" key={i}>
                                                    <Td>{i + 1}</Td>
                                                    <Td>{element?.name}</Td>
                                                    <Td>{element?.exam?.length}</Td>
                                                </Tr>
                                            )) : null
                                        }
                                    </Tbody>
                                </Table>
                            </Wrapper>
                            {!exams?.length && <EmptyData data={[]} loading={loading} />}
                        </Col>
                        <Col span={24} >{t("Count")}: {exams?.length ? exams?.length : 0}</Col>
                    </Row>
                </Wrapper>
            </CardBody>
        </Card>
    )


}


export default StudentExams;
