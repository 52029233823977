/** @format */

import styled, { css } from "styled-components";

export const StudentMobileHeaderUi = styled.div<{ theme: any }>`
  background-color: ${(p) => p.theme.name === "black" ? p.theme.card : "white"};
  position: fixed;
  padding: 1rem 0.5rem;
  width: 100%;
  height: auto;
  box-shadow: ${ props => props.theme.name === "white" ? "0px 10px 30px rgba(209, 213, 223, 0.5)" : "rgba(0, 0, 0, 0.4) 0px 1px 10px"};
  // -webkit-box-shadow: 0px 10px 30px rgb(209 213 223 / 50%);
  z-index: 2;

  .std_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    height: 30px;
  }
`;
