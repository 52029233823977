import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import instance from "config/_axios"
import store from "store";
import getAsyncInitialData from "./async_requests";
import { asyncN } from "utils/notifications";



const UserMe = createAsyncThunk(
    'user/UserMe',
    async (url: string, { rejectWithValue }) => {
        try {

            const response = await instance({ url, method: 'GET' });

            console.log("user me ",response.data)
            return response.data;


        } catch (error: any) {

            return rejectWithValue(error.response);
        }
    }
)

export default UserMe;


export const delete_data = async (url: string, id: number, data?: any): Promise<any> => {
    try {
        const response = await instance(data ? { url: `${url}/${id}`, method: "DELETE", data: data } : { url: `${url}/${id}`, method: "DELETE" });

        if (response.data?.status) {
            asyncN('success', 'delete', response.data?.message)
        } else {
            asyncN('error', 'delete', response.data?.message)
        }

        return new Promise((resolve, reject) => {
            resolve("success")
            reject("Something went wrong while deleting data!")
        })

    } catch (error) {
        message.error("Something went wrong !", 1)
    }

}

export const getAllLanguages = async (path_prefix?: string) => {
    try {
        const { data } = store.getState().data.languages;

        if (store.getState().data.languages.data.length === 0) {
            await store.dispatch(getAsyncInitialData({ key: 'languages', url: 'languages', path_prefix }))
            return {
                items: store.getState().data.languages.data
            }
        } else {
            return { items: data }
        }

    } catch (error: any) {
        message.error(error?.response?.message)
    }
}



export const changeItemStatus = async (path_prefix?: string, status?: boolean) => {
    try {
        const formdata = new FormData();
        formdata.append("status", status ? "1" : "0")
        const response = await instance({ url: path_prefix, method: 'PUT', data: formdata });
        if (response?.data?.status === 1) {
            if (response?.data?.data?.status === 1) {
                return {
                    backgroundColor: "green",
                }
            } else {
                return {
                    backgroundColor: "red",
                }
            }
        } else {
            return new Error(response.data?.message)
        }
    } catch (error) {

    }
}


export const getPassword = async (id: number) => {
    try {
        const response = await instance({ url: 'passwords/' + id, method: 'GET' });
        if (response?.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response?.data?.message)
        }
    } catch (error) {

    }
}


export const getAllWeeks = async (path_prefix?: string) => {
    try {

        const response = await instance({ url: `/weeks${path_prefix ? '?' + path_prefix : ''}`, method: "GET" });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllEduSemesters = async (perPage?: number | string, currentPage?: number | string, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/edu-semestrs${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllTracherAccess = async (perPage?: number | string, currentPage?: number | string, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/teacher-accesses${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}
export const getAllSemesters = async (perPage?: number | string, currentPage?: number | string, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/teacher-accesses${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}


export const getAllSemestrs = async (perPage?: number | string, currentPage?: number | string, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/semestrs${path_prefix ? "?" + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}


export const getAllEduSemesterSubjects = async (perPage?: number | string, currentPage?: number | string, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/edu-semestr-subjects${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}


export const getAllTimeTables = async (perPage?: number | string, currentPage?: number | string, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/time-tables${path_prefix ? "?" + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllExams = async (perPage?: number, currentPage?: number, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/exams${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getOneExam = async (id: number, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/exams/${id}${path_prefix ? '?' + path_prefix : ''}`, method: "GET" });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllQuestionTypes = async (perPage?: number, currentPage?: number, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/question-types${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}



export const getNotifications = async () => {
    try {
        await store.dispatch(getAsyncInitialData({ key: 'messages', url: 'notifications', path_prefix: "sort=-id&expand=statusName" }))
        return {
            items: store.getState().data.messages.messages
        }

    } catch (error: any) {
        message.error(error?.response?.message)
    }
}
