import { BiInfoCircle } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import styles from './styles.module.scss';


const InteractiveServices = () => {


    return (
        <Card>
            <CardBody>
                <div className='pt-3'>
                    <NavLink to={"/dormitory"} className="text-decoration-none">
                        <div className={styles.list_item} >
                            <span>
                                <BiInfoCircle fontSize={24} color="#1967d2" />
                                Talabalar turar joyi
                            </span>
                            <span>

                            </span>
                        </div>
                    </NavLink>
                </div>
            </CardBody>
        </Card>
    )


}




export default InteractiveServices;