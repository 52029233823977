import { FC, useState } from "react";
import { FaBell } from "react-icons/fa";
import { BiMenu, BiLogOut } from "react-icons/bi";
import { Popover, Skeleton } from 'antd';
import { StudentMobileHeaderUi } from './common/styled'
import "./common/styles.scss";
import { useDispatch } from "react-redux";
import { manageSidebar } from "store/ui";
import { SelectStudentTheme } from "config/theme/colors";
import IconBtnCmponent from "../header/components/IconsBtnComponent";
import _logout from "config/_axios/logout";
import LanguageMobile from "./components/Language";
import logo from "assets/images/kiu_logo.png";

const StudentHeaderMobile: FC = (): JSX.Element => {


    const dispatch = useDispatch();
    const theme = SelectStudentTheme();
    const [visible, setVisible] = useState<boolean>(false)
    const clickBtn = () => {
        dispatch(manageSidebar({}))
    }

    return (
        <StudentMobileHeaderUi theme={theme}>
            <div className="std_header">
                <div className="d-f" >
                <button className="sidebar_btn" onClick={clickBtn}><BiMenu size={24} color={theme.blue} /></button>
                <div className="d-f px-3">
                    <div className="sidebar_avatar me-2">
                        <img src={logo} style={{ color: theme.blue }} alt="" />
                    </div>
                    <h5 style={{ color: theme.blue, fontWeight: "bold", fontSize: "20px", margin: 0 }}>KIU</h5>
                </div>
                </div>
                <div className="d-flex text-end">
                    <Popover
                        content={<div style={{ width: "180px" }} ><Skeleton /></div>}
                        trigger="click"
                        visible={visible}
                        onVisibleChange={(visible) => setVisible(visible)}
                    >
                        <IconBtnCmponent icon={<FaBell color={theme.blue} size={18} onClick={() => setVisible(true)} />} isBadge={true} />
                    </Popover>
                    <LanguageMobile />
                    <IconBtnCmponent icon={<BiLogOut color={theme.blue} onClick={_logout} style={{ transform: "rotate(-180deg)" }} size={20} />} isBadge={false} />
                </div>
            </div>
        </StudentMobileHeaderUi>
    )

}


export default StudentHeaderMobile;