import instance from "."
import { asyncN } from "utils/notifications"
import store from "store"
import { logout } from "store/auth"
import { message } from "antd"
import { createBrowserHistory } from 'history';
import { EXAM_ACTIONS } from "store/exam"


const _logout = async () => {
    try {
        const history = createBrowserHistory();
        const response = await instance({ url: '/auth/logout', method: 'POST' })
        if (response.data?.status === 1) {
            localStorage.removeItem('access_token')
            asyncN('success', 'read', response.data?.message).then(() => {
                localStorage.clear();
                sessionStorage.clear();
                history.push('/');
                store.dispatch(logout());
            })
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const _logoutExam = async () => {
    try {
        const history = createBrowserHistory();
        const response = await instance({ url: '/auth/logout', method: 'POST' });
        if (response.data?.status === 1) {
            localStorage.removeItem('access_token')
            asyncN('success', 'read', response.data?.message).then(() => {
                localStorage.clear();
                store.dispatch(EXAM_ACTIONS.clrearExamData());
                sessionStorage.clear();
                history.push('/');
                store.dispatch(logout());
            })
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}


export default _logout