import { FILE_URL } from "config/utils";
import store from "store";
import { EXAM_ACTIONS } from "store/exam";




export function changeExamQuestion(exam_question_id: number) {

    localStorage.setItem("exam_question_id", String(exam_question_id));

    store.dispatch(EXAM_ACTIONS.selectExamQuestion({ exam_question_id }));

}



