import { ITimeTable } from "models/others";
import React from "react";
import { BiBookOpen, BiTime, BiUserVoice } from "react-icons/bi";
import styles from '../styles.module.scss';

type PropsInformer = {
    element: ITimeTable
}

const TimeTableSelectItem: React.FC<PropsInformer> = ({ element }): JSX.Element => {


    return (
        <div key={element.id} className={styles.time_table_select_card} >
            <span><BiTime />{element.week?.name} / {element.para?.start_time}-{element.para?.end_time} / {element.subjectCategory?.name}</span>
            <span><BiBookOpen />{element.subject?.name}</span>
            <span><BiUserVoice /><a href="https://kiu.uz/uz/all-teachers" target="_blank" >{element.teacher?.last_name}&nbsp;{element.teacher?.first_name}.&nbsp;{String(element.teacher?.middle_name).charAt(0).toUpperCase()}</a></span>
        </div>
    )

}

export default React.memo(TimeTableSelectItem);