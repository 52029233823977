import { Row, Col, Collapse, Divider, Tag, Input, Card, Modal, Button, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card as CardWrapper, CardBody as CardBodyWrapper } from "reactstrap";
import CustomBreadcrumb from "components/Breadcrumb";
import { match, useRouteMatch } from "react-router"
import moment from "moment";
import { SpinnerLoading } from "components/Spinner";
import instance from "config/_axios";
import { IExamControl, IExamControlStudent } from "models/exam";
import OpenFileNewWindowButton from "components/openFileNewWindowButton";
import FormLabelCrud from "components/FormLabel/FormLabelCrud";
import "./style.scss"
import FormLabel from "components/FormLabel";
import { IoClose } from "react-icons/io5";
import isHasAuthRoute from "utils/isHasRoute";

const ExamControlView = () => {

  const { t } = useTranslation();
  const match: match<any> | null = useRouteMatch("/exam_control_view/:time_table_id/:question_id");
  const [examControl, setExamControl] = useState<IExamControl>();
  const [examControlStudent, setExamControlStudent] = useState<IExamControlStudent>();
  const [loading, setLoading] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [isAppeal, setIsAppeal] = useState<boolean>(false);
  const [appeal, setAppeal] = useState<string | null>();
  const [now, setNow] = useState<any>([]);

  const _breadcrump = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Subjects",
      path: `std_subjects`,
    },
    {
      name: "Topshiriqlar",
      path: `std_subject/subject_exercisses/${match?.params?.time_table_id}`,
    },
    {
      name: "Oraliq nazoratni ko'rish",
      path: "",
    }
  ];

  useEffect(() => {
    (
      async () => {
        try {
          setLoading(p => !p)
          const responce = await instance({ url: `/time-tables/${match?.params?.time_table_id}?expand=examControl,examControl.examControlStudents,now` });
          const exam_control = responce.data.data.examControl;
          if (responce.data.status === 1) {
            if (exam_control?.examControlStudents?.length) {
              setExamControlStudent(exam_control.examControlStudents[0]);
            }
            setExamControl(exam_control);
            setNow(responce.data.data?.now)
          }

          setLoading(p => !p);
        } catch (error) { setLoading(p => !p); }
      }
    )()
  }, [refetch]);

  const openModal = (question: 1 | 2) => {
    setIsAppeal(true);
    if (question === 1) {
      setAppeal(examControlStudent?.appeal_text)
    }
    if (question === 2) {
      setAppeal(examControlStudent?.appeal2_text)
    }
  }

  const sendAppeal = async (question: '1' | '2') => {
    if ((question === '1' || question === '2' || examControlStudent) && appeal) {
      const formdata = new FormData;
      if (question === '1') {
        formdata.append('appeal_text', `${appeal}`)
      }
      if (question === '2') {
        formdata.append('appeal2_text', `${appeal}`)
      }

      const res = await instance({ url: `/exam-control-students/appeal/${examControlStudent?.id}`, method: "POST", data: formdata })
      if (res.data.status === 1) {
        setAppeal('');
        setIsAppeal(false)
        setRefetch(p => !p);
      }
    } else {
      message.error("Apelyatsiya matnini kiritish zarur");
    }
  }

  return (
    <>
      <SpinnerLoading loading={loading} color="#1890ff" size={28}>
        <Modal
          visible={!!isAppeal}
          style={{ top: 60, padding: 0 }}
          footer={null}
          title={"Apelyatsiya berish"}
          className="appeal-modal"
          closeIcon={<IoClose size={24} onClick={() => { setIsAppeal(false); setAppeal('') }} />}
        >
          <Input.TextArea value={appeal ?? ''} onChange={(e) => setAppeal(e.target.value)} placeholder="Apelyatsiya matnini kiriting" rows={6} className="w-100" />
          <Divider className="mb-2 mt-3" />
          <div className="text-end">
            <Button type="primary" size="small" danger onClick={() => { setIsAppeal(false); setAppeal('') }} className="me-2" >{t("Cancel")}</Button>
            <Button type="primary" size="small" onClick={() => sendAppeal(match?.params?.question_id)} >{t("Save")}</Button>
          </div>
        </Modal>
        <CardWrapper>
          <CardBodyWrapper>
            <CustomBreadcrumb arr={_breadcrump} />
            <hr />
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <p><strong>Nomi:</strong>&nbsp;{examControl?.name || null}</p>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                {match?.params?.question_id === "1" ? <p><strong>1-topshiriq muddati:</strong>&nbsp;{examControl?.start ? moment.unix(Number(examControl?.start)).format("DD.MM.YYYY | HH:mm") : null} - {examControl?.finish ? moment.unix(Number(examControl?.finish)).format("DD.MM.YYYY | HH:mm") : null}</p> : null}
                {match?.params?.question_id === "2" ? <p><strong>2-topshiriq muddati:</strong>&nbsp;{examControl?.start2 ? moment.unix(Number(examControl?.start2)).format("DD.MM.YYYY | HH:mm") : null} - {examControl?.finish2 ? moment.unix(Number(examControl?.finish2)).format("DD.MM.YYYY | HH:mm") : null}</p> : null}
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                {match?.params?.question_id === "1" && examControl?.status === 2 ? <p><strong>Apelyatsiya muddati:</strong>&nbsp;{examControl?.appeal_at ? moment.unix(Number(examControl?.appeal_at)).format("DD.MM.YYYY | HH:mm") : null} </p> : null}
                {match?.params?.question_id === "2" && examControl?.status2 === 2 ? <p><strong>Apelyatsiya muddati:</strong>&nbsp;{examControl?.appeal2_at ? moment.unix(Number(examControl?.appeal2_at)).format("DD.MM.YYYY | HH:mm") : null}</p> : null}
              </Col>
              { isHasAuthRoute("exam-control-student_appeal") ? <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                {match?.params?.question_id === "1" && (examControlStudent?.ball || examControlStudent?.ball === 0) && examControl?.status === 2 && (examControl?.appeal_at && now.length && (examControl?.appeal_at >= now[0])) ? <Button size="small" type="primary" className="me-2" onClick={() => openModal(1)} >1-topshiriq uchun {examControlStudent?.appeal_text ? "Apelyatsiyani taxrirlash" : "Apelyatsiya berish"}</Button> : null}
                {match?.params?.question_id === "2" && (examControlStudent?.ball2 || examControlStudent?.ball2 === 0) && examControl?.status2 === 2 && (examControl?.appeal2_at && now.length && (examControl?.appeal2_at >= now[0])) ? <Button size="small" type="primary" className="me-2" onClick={() => openModal(2)} >2-topshiriq uchun {examControlStudent?.appeal2_text ? "Apelyatsiyani taxrirlash" : "Apelyatsiya berish"}</Button> : null}
              </Col> : null}
            </Row>
          </CardBodyWrapper>
        </CardWrapper>
        {/* <div className="bg-light p-3"> */}
        { (examControlStudent?.appeal_text && match?.params?.question_id === "1") || (examControlStudent?.appeal2_text && match?.params?.question_id === "2") ? <CardWrapper className="mt-3">
          <CardBodyWrapper>
            <div className="d-f mb-3"><h6 className="m-0" style={{ color: "rgb(24,144,255)" }} >Apelyatsiya </h6>&nbsp;<span>({match?.params?.question_id}-topshiriq)</span></div>
            {/* <Divider className="my-2" /> */}
            <FormLabel ><b className="question-colo">Talaba apelyatsiyasi</b></FormLabel>
            { match?.params?.question_id === "1" ? <div className='status-exam-control orange w-100 m-0 mb-2' style={{ minHeight: 80 }} dangerouslySetInnerHTML={{ __html: examControlStudent?.appeal_text ?? '' }} ></div> : null}
            { match?.params?.question_id === "2" ? <div className='status-exam-control orange w-100 m-0 mb-2' style={{ minHeight: 80 }} dangerouslySetInnerHTML={{ __html: examControlStudent?.appeal2_text ?? '' }} ></div> : null}
            { ((examControlStudent.appeal_conclution && examControlStudent?.appeal) && match?.params?.question_id === "1") || ((examControlStudent.appeal2_conclution && examControlStudent?.appeal2) && match?.params?.question_id === "2") ? <Row gutter={[12, 12]}>
              <Col span={24}><Divider className="m-0 p-0 mt-3" /></Col>
              <Col xs={24} sm={24} md={24} lg={16} xl={16} >
                <FormLabel ><b className="question-colo">Apelyatsiya xulosasi</b></FormLabel>
                { match?.params?.question_id === "1" ? <div className='status-exam-control blue w-100 m-0 mb-2' style={{ minHeight: 80 }} dangerouslySetInnerHTML={{ __html: examControlStudent?.appeal_conclution ?? '' }} ></div> : null}
                { match?.params?.question_id === "2" ? <div className='status-exam-control blue w-100 m-0 mb-2' style={{ minHeight: 80 }} dangerouslySetInnerHTML={{ __html: examControlStudent?.appeal2_conclution ?? '' }} ></div> : null}
              </Col>
              {(examControlStudent?.appeal === 2 && match?.params?.question_id === "1") || (examControlStudent?.appeal2 === 2 && match?.params?.question_id === "2") ? <Col xl={8} lg={16} md={24} sm={24} xs={24} className="w-100">
                <FormLabel ><b className="question-colo">Apelyatsiya bali va holati</b></FormLabel>
                { match?.params?.question_id === "1" ? <span className="status-exam-control green _14 m-0 w-100 text-center" >{t("Apelyatsiya bali")}: <b>{examControlStudent?.ball}</b></span> : null}
                { match?.params?.question_id === "2" ? <span className="status-exam-control green _14 m-0 w-100 text-center" >{t("Apelyatsiya bali")}: <strong>{examControlStudent?.ball2}</strong></span> : null}
                {/* <span className="status-exam-control green _14 m-0 mt-3 w-100 text-center" >{t("Tekshirilgan")}</span> */}
                { match?.params?.question_id === "1" && examControlStudent?.appeal_status === 1 ? <div className="status-exam-control _14 green _14 m-0 mt-3 w-100 text-center" >Asosli</div> : null}
                { match?.params?.question_id === "2" && examControlStudent?.appeal2_status === 1 ? <div className="status-exam-control _14 green _14 m-0 mt-3 w-100 text-center" >Asosli</div> : null}
                { match?.params?.question_id === "1" && examControlStudent?.appeal_status === 2 ? <div className="status-exam-control _14 red _14 m-0 mt-3 w-100 text-center" >Asossiz</div> : null}
                { match?.params?.question_id === "2" && examControlStudent?.appeal2_status === 2 ? <div className="status-exam-control _14 red _14 m-0 mt-3 w-100 text-center" >Asossiz</div> : null}
                { match?.params?.question_id === "1" && examControlStudent?.appeal_status === 3 ? <div className="status-exam-control _14 orange _14 m-0 mt-3 w-100 text-center" >Texnik</div> : null}
                { match?.params?.question_id === "2" && examControlStudent?.appeal2_status === 3 ? <div className="status-exam-control _14 orange _14 m-0 mt-3 w-100 text-center" >Texnik</div> : null}
                { match?.params?.question_id === "1" && examControlStudent?.appeal_status === 4 ? <div className="status-exam-control _14 blue _14 m-0 mt-3 w-100 text-center " >Asosli texnik</div> : null}
                { match?.params?.question_id === "2" && examControlStudent?.appeal2_status === 4 ? <div className="status-exam-control _14 blue _14 m-0 mt-3 w-100 text-center " >Asosli texnik</div> : null}
              </Col> : null}
            </Row> : null}
          </CardBodyWrapper>
        </CardWrapper> : null}
        {(examControl?.question || examControl?.question_file) && match?.params?.question_id === "1" ? <CardWrapper className="mt-3" >
          <Collapse bordered={false} ghost activeKey={["1"]} className="rounded-3" >
            <Collapse.Panel key="1" header={<div className="d-f justify-content-between w-100" >
              <h6 style={{ color: "rgb(24,144,255)" }} >1-Topshiriq: </h6>
              <div>
                {/* {examControl?.examControlStudents?.length && examControl.status === 2 && (examControl.appeal_at && (examControl.appeal_at > now[0])) ? <Button size="small" type="primary" className="me-2" onClick={() => setIsAppeal(1)} >Apelyatsiya berish</Button> : null} */}
                <Tag color="blue" className="border border-0 py-1 px-2" >Maksimal ball: {examControl?.max_ball}</Tag>
              </div>
            </div>} className="">
              <Row gutter={[12, 12]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                  <FormLabel ><b className="question-color">Topshiriq matni</b></FormLabel>
                  <div className='border px-2 py-1 mb-2' style={{ minHeight: 140 }} dangerouslySetInnerHTML={{ __html: examControl?.question ?? 'Topshiriq matni mavjud emas' }} ></div>
                  {
                    examControl?.question_file ? <div className="d-f question-color mt-2"><span>Topshiriq fayli:&nbsp;</span><OpenFileNewWindowButton url={examControl?.question_file} /></div> : null
                  }
                </Col>
                <Col span={24}>
                  <Divider orientation="left" plain className="mb-2" ><span style={{ color: "#40AD5D", fontSize: 15, fontWeight: 500 }}>{t("Javob")}</span></Divider>
                </Col>
                {examControlStudent?.plagiat_file ? <Col span={24}>
                  <Card>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className="m-0 p-0 d-f"><strong>plagiat fayli:</strong>&nbsp;<OpenFileNewWindowButton url={examControlStudent?.plagiat_file} /></div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <p className="m-0 p-0"><strong>O'xshashligi (%):</strong>&nbsp;&nbsp;{examControlStudent?.plagiat_percent ? examControlStudent?.plagiat_percent + ' %' : null}</p>
                      </Col>
                    </Row>
                  </Card>
                </Col> : null}
                <Col xs={24} sm={24} md={24} lg={24} xl={ examControlStudent?.ball || examControlStudent?.ball===0 ? 16 : 24} >
                  <FormLabel ><b className="answer-color">Javob matni</b></FormLabel>
                  <div className='border px-2 py-1 mb-2' style={{ minHeight: 140 }} dangerouslySetInnerHTML={{ __html: `${examControlStudent?.answer}` }} ></div>
                  {examControlStudent?.answer_file ?
                    <div className="d-f answer-color mt-3"><span>Javob fayli:&nbsp;&nbsp;</span><OpenFileNewWindowButton tag url={examControlStudent.answer_file} /></div> : null}
                </Col>
                { examControlStudent?.ball || examControlStudent?.ball===0 ? <Col xl={ 8} lg={24} md={24} sm={24} xs={24} className="w-100">
                  <Row gutter={[12, 12]} className="w-100">
                    <Col span={24}>
                      <FormLabelCrud>O'qituvchining xulosasi</FormLabelCrud>
                      <Input.TextArea readOnly rows={4} value={examControlStudent?.conclution ?? ''} placeholder="Xulosa matnini kiriting ..." />
                    </Col>
                    <Col span={24}>
                      <Tag color="#E6F4EA" style={{ color: "#40AD5D" }} className="border border-0 py-1 px-2 w-100 text-center" >{t("O'quvchi bali")}: <strong>{ examControlStudent?.old_ball ?? examControlStudent?.ball}</strong></Tag>
                    </Col>
                  </Row>
                </Col> : null}
              </Row>
            </Collapse.Panel>
          </Collapse>
        </CardWrapper> : null}
        {(examControl?.question2 || examControl?.question2_file) && match?.params?.question_id === "2" ? <CardWrapper className="mt-3" >
          <Collapse bordered={false} ghost activeKey={["1"]} className="rounded-3" >
            <Collapse.Panel key="1" header={<div className="d-f justify-content-between w-100" >
              <h6 style={{ color: "rgb(24,144,255)" }} >2-Topshiriq: </h6>
              <div>
                {/* {examControl?.examControlStudents?.length && examControl.status2 === 2 ? <Button size="small" className="me-2" type="primary" onClick={() => openModal(2)} >Apelyatsiya berish</Button> : null} */}
                <Tag color="blue" className="border border-0 py-1 px-2" >Maksimal ball: {examControl?.max_ball2}</Tag>
              </div>
            </div>} className="">
              <Row gutter={[12, 12]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                  <FormLabel ><b className="question-color">Topshiriq matni</b></FormLabel>
                  <div className='border px-2 py-1 mb-2' style={{ minHeight: 140 }} dangerouslySetInnerHTML={{ __html: examControl?.question2 ?? 'Topshiriq matni mavjud emas' }} ></div>
                  {
                    examControl?.question2_file ? <div className="d-f question-color mt-2"><span>Topshiriq fayli:&nbsp;</span><OpenFileNewWindowButton url={examControl?.question2_file} /></div> : null
                  }
                </Col>
                <Col span={24}>
                  <Divider orientation="left" plain className="mb-2" ><span style={{ color: "#40AD5D", fontSize: 15, fontWeight: 500 }}>{t("Javob")}</span></Divider>
                </Col>
                { examControlStudent?.plagiat2_file ? <Col span={24}>
                  <Card>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className="mb-0 d-f"><strong>plagiat fayli:</strong>&nbsp;<OpenFileNewWindowButton url={examControlStudent?.plagiat2_file} /></div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <p className="mb-0"><strong>O'xshashligi (%):</strong>&nbsp;&nbsp;{examControlStudent?.plagiat2_percent ? examControlStudent?.plagiat2_percent + ' %' : null}</p>
                      </Col>
                    </Row>
                  </Card>
                </Col> : null}
                <Col xs={24} sm={24} md={24} lg={24} xl={ examControlStudent?.ball2 || examControlStudent?.ball2 === 0 ? 16 : 24} >
                  <FormLabel ><b className="answer-color">Javob matni</b></FormLabel>
                  <div className='border px-2 py-1 mb-2' style={{ minHeight: 140 }} dangerouslySetInnerHTML={{ __html: `${examControlStudent?.answer2}` }} ></div>
                  { examControlStudent?.answer2_file ?
                    <div className="d-f answer-color mt-3"><span>Javob fayli:&nbsp;&nbsp;</span><OpenFileNewWindowButton tag url={examControlStudent.answer2_file} /></div> : null}
                </Col>
                { examControlStudent?.ball2 || examControlStudent?.ball2 === 0 ? <Col xl={8} lg={24} md={24} sm={24} xs={24} className="w-100">
                  <Row gutter={[12, 12]} className="w-100">
                    <Col span={24}>
                      <FormLabelCrud>O'qituvchining xulosasi</FormLabelCrud>
                      <Input.TextArea readOnly rows={4} value={examControlStudent?.conclution2 ?? ''} placeholder="Xulosa matnini kiriting ..." />
                    </Col>
                    <Col span={24}>
                      <Tag color="#E6F4EA" style={{ color: "#40AD5D" }} className="border border-0 py-1 px-2 w-100 text-center" >{t("O'quvchi bali")}: <strong>{ examControlStudent?.old_ball2 ?? examControlStudent?.ball2}</strong></Tag>
                    </Col>
                  </Row>
                </Col> : null}
              </Row>
            </Collapse.Panel>
          </Collapse>
        </CardWrapper> : null}
        {/* </div> */}
        {/* </CardBodyWrapper>
    </CardWrapper> */}
      </SpinnerLoading>
    </>
  )

}




export default ExamControlView;