import { FC } from "react";
import { Divider, Drawer, Slider } from "antd";
import { FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { SelectStudentTheme } from "config/theme/colors";
import { RightSitebarWrapper } from "./stylt";


const RightSitebar: FC<{ isSetting: boolean, setIsSetting: (isSetting: boolean) => void }> = ({ isSetting, setIsSetting }): JSX.Element => {

  const theme = SelectStudentTheme();
  const marks = { 13: '13px', 14: '14px', 15: '15px', 16: '16px', 17: '17px', 18: '18px' }

  return (
    <RightSitebarWrapper theme={theme} >
      <Drawer
        placement="right"
        closable={false}
        onClose={() => setIsSetting(false)}
        visible={isSetting}
        getContainer={false}
        width={260}
        style={{ position: 'fixed', top: '60px', zIndex: 1 }}
      >
        {/* <div className="avatar">
          <div className="img"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="close"><FaTimes size={18} color={theme.text} onClick={() => setIsSetting(false)} /></div>
        </div> */}
        <Divider orientation="left" plain>Theme</Divider>
        <div className="theme">
          <div>dark</div>
          <div>light</div>
        </div>
        <Divider orientation="left" plain>Typografy</Divider>
        <div className="text_size"><Slider marks={marks} min={13} max={18} defaultValue={15} /></div>
        {/* <Divider orientation="left" plain>Profile</Divider> */}
        <NavLink to={"/student_profile"} style={{textDecoration: "none"}} ><div className="profile">Profile page</div></NavLink>
        <Divider orientation="left" plain>Other</Divider>
        <div className="other">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Drawer>
    </RightSitebarWrapper>
  )
}

export default RightSitebar;