import { Alert, Button, Col, Row } from 'antd';
import { FC } from 'react';
import { RiBook3Fill } from 'react-icons/ri';
import { FaChartPie, FaCalendarCheck, FaCalendarAlt, FaFileSignature } from 'react-icons/fa';
import { IoLibrarySharp } from 'react-icons/io5';
import { SelectStudentTheme } from 'config/theme/colors';
import { NavLink, useHistory } from 'react-router-dom';
import { Wrapper } from './common/styled';
import { useTranslation } from 'react-i18next';
import { CardBody, Card as CardWrapper } from 'reactstrap';
import Statute from 'pages/statute';
import StatuteInHomePage from 'pages/statute/statute_home_page';



const StudentProfileHomePage: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const theme = SelectStudentTheme();
    const history = useHistory();

    return (
        <CardWrapper>
            <CardBody>
                <Wrapper theme={theme}>
                    <div className="row" >
                        <Col onClick={() => { history.push({ pathname: "/std_subjects" }) }}>
                            <h1><RiBook3Fill /></h1>{t("Subjects")}
                        </Col>
                        <Col onClick={() => { history.push({ pathname: "/time-table_variant_index" }) }}>
                            <h1><FaCalendarAlt /></h1> {t("Time table")}
                        </Col>
                        <Col
                            onClick={() => { history.push({ pathname: "/std_attendances/:subject_id" }) }}
                        >
                            <h1><FaCalendarCheck /></h1> {t("Attendance")}
                        </Col>
                        <Col onClick={() => { history.push({ pathname: "/std_exams" }) }}>
                            <h1><FaFileSignature /></h1> {t("Exams")}
                        </Col>
                        <Col
                        // onClick={() => { history.push({ pathname: "/assimilation" }) }}
                        >
                            <h1><FaChartPie /></h1> {t("Progress")}
                        </Col>
                        <Col onClick={() => { history.push({ pathname: "/std_library" }) }} >
                            <h1><IoLibrarySharp /></h1> {t("Library")}
                        </Col>
                    </div>
                    <Row>
                        {/* <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                            <StatuteInHomePage />
                        </Col> */}
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="message" >
                            <Alert
                                style={{ borderRadius: ".25rem" }}
                                message={<span style={{ color: '#1890FF' }} >Talabalar turar joyi</span>}
                                description={
                                    <span>
                                        Talabalar turar joyiga talabalarni joylashtirish tizimi endilikda ushbu tizimda ariza topshirish orqali amalga oshiriladi.
                                        <NavLink to={'/dormitory'}><Button type="link" >Ariza qoldirish</Button></NavLink>
                                    </span>
                                }
                                type="info"
                                showIcon
                                banner
                            />
                        </Col>
                    </Row>
                </Wrapper>
            </CardBody>
        </CardWrapper>
    )
}

export default StudentProfileHomePage;