import { SelectStudentTheme } from "config/theme/colors";
import { FC } from "react";
import { SidebarFooterUi } from "./styled";



const SidebarFooter: FC = (): JSX.Element => {

    const theme = SelectStudentTheme();

    return (
        <SidebarFooterUi theme={theme} >
            <span>version: 0.1</span>
        </SidebarFooterUi>
    )
}


export default SidebarFooter;