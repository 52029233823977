import React, { FC, useEffect, useState } from "react";
import StudentHeader from '../dashboard/vertical/header/index';
import StudentHeaderMobile from '../dashboard/vertical/header_mobile';
import StudentContent from '../dashboard/vertical/content'
import { SelectStudentTheme } from "config/theme/colors";
import Sidebar from '../dashboard/vertical/sidebar';
import "./styles.scss";
import { StdContent } from "../dashboard/vertical/common/styled";
import { useAppSelector } from "store/services";
import RightSitebar from "../dashboard/vertical/right_sidebar";
import { useDispatch } from "react-redux";
import { manageSidebarCustom } from "store/ui";
import useWindowSize from "hooks/useWindowSize";

const Layout: FC<any> = (props): JSX.Element => {

    let isMobile = /iPhone|Android/i.test(navigator.userAgent);
    const width = useWindowSize().width;
    const _sidebar = useAppSelector(state => state.ui.sidebar);
    const [isSetting, setIsSetting] = useState<boolean>(false);
    const theme = SelectStudentTheme();
    const dispatch = useDispatch();

    useEffect(() => {
        if(isMobile) dispatch(manageSidebarCustom({ sidebarType: 'xs' }));
    }, [])

    return (
        <div className="student_profile" style={{ backgroundColor: "#F3F7FF" }}>
            {
                <div className="wrapper">
                    <div className="d-flex sidebar_content">
                        {isMobile || width < 576 ? <StudentHeaderMobile /> : <StudentHeader isSetting={isSetting} setIsSetting={setIsSetting} />}
                        <StdContent theme={theme} sidebar={_sidebar}>
                            <Sidebar />
                            <StudentContent isSetting={isSetting} setIsSetting={setIsSetting} isMobile={isMobile}>{props.children}</StudentContent>
                            <RightSitebar isSetting={isSetting} setIsSetting={setIsSetting} />
                        </StdContent>
                    </div>
                </div>
            }
        </div>
    )
}


export default React.memo(Layout);