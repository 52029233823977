import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import { save_after_load, save_before_load } from 'utils/save_ui';
import { Loading } from './components/Loading';
import verification from './config/_axios/verification';
import RoutesMiddleware from 'routes/RoutesMiddleware'
import ErrorBoundary from 'pages/error_boundary';
import UserMe, { getNotifications } from 'services'
import { useAppDispatch, useAppSelector } from 'store/services';
import { TypeInitialStateAuth } from 'store/auth';
import { handleNetwork } from 'store/ui';
import { Alert, notification } from 'antd';
import { TypeInitialStateUser } from 'store/user';
import { clearExamData } from 'utils/clearExamData';


function App() {
  // const history = useHistory()
  const auth = useAppSelector(state => state.auth) as TypeInitialStateAuth;
  // const user = useAppSelector(state => state.user) as TypeInitialStateUser;
  const dispatch: any = useAppDispatch();

  if (!localStorage.getItem("_url") && window.location.pathname !== "/" && !localStorage.getItem("access_token")) {
    localStorage.setItem("_url", window.location.pathname);
  }
  // console.log("user => ", user.user)
  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      if (localStorage.getItem("access_token")) {
        sessionStorage.setItem("page_reloading", '_1_')
      }
      save_before_load();
    });
    return () => window.removeEventListener('beforeunload', save_before_load);
  }, [])

  // useEffect(() => {
  //   window.addEventListener("load", () => {
  //     verification()
  //     save_after_load()
  //   });
  //   return () => window.removeEventListener('load', save_after_load);
  // }, [])
  useEffect(() => {
    // window.addEventListener("load", () => {
    if (localStorage.getItem("access_token")) {
      verification()
    }
    save_after_load()
    // });
    return () => window.removeEventListener('load', save_after_load);
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated) {
      (
        async () => {
          await dispatch(UserMe('/students/me?expand=profile'))
          await getNotifications();
        }
      )()
      clearExamData();
    }
  }, [auth.isAuthenticated])

  // useEffect(() => {
  //   window.addEventListener("online", () => {
  //     dispatch(handleNetwork({ isOnline: navigator.onLine }));
  //     notification.success({ message: "Tarmoqga ulandi !", placement: "bottomRight", duration: 5 });
  //   });
  //   window.addEventListener("offline", () => {
  //     dispatch(handleNetwork({ isOnline: navigator.onLine }));
  //     notification.warning({ message: "Tarmoqdan uzilish mavjud !", placement: "bottomRight", duration: 5 });
  //   });
  // }, [navigator.onLine])

  if (sessionStorage.getItem('page_reloading') === '_1_') {
    return <Loading />
  }
  if (auth.isLoading) {
    return <Loading />
  }

  const _url: string = localStorage.getItem("_url") || window.location.pathname;

  return (
    <>
      <RoutesMiddleware />
      {auth.isAuthenticated && <Redirect to={_url} />}
    </>
  );
}

export default App;