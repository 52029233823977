import { FC } from 'react';
import { FaSearch } from 'react-icons/fa';
import { SelectStudentTheme } from 'config/theme/colors';
import { SearchBarUi } from "./styled"

const SearchBar:FC<{screen?:string}> = ({screen}) => {
    const theme = SelectStudentTheme();

    return (
        <SearchBarUi screen={screen} theme={theme}>
            <input type="text" placeholder="Search text ..." />
            <button type="submit" onClick={e => e.preventDefault()}> <span>Search</span> <FaSearch /> </button>
        </SearchBarUi>
    )

}



export default SearchBar