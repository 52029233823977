import { FC, useState } from "react";
import { Menu, Dropdown } from 'antd';
import UzbImage from "assets/images/uzb-flag.png"
import RusImage from "assets/images/rus-flag.jpg"
import EnImage from "assets/images/en-flag.jpg"
import i18n from 'config/i18n';
import "../common/styles.scss";
import { SelectStudentTheme } from "config/theme/colors";



const LanguageMobile: FC<any> = (props): JSX.Element => {

    const theme = SelectStudentTheme();
    const langs = [
        {
            id: 1,
            title: "Uz",
            key: "uz",
            img: UzbImage
        },
        {
            id: 3,
            title: "Ru",
            key: "ru",
            img: RusImage
        },
        {
            id: 4,
            title: "En",
            key: "en",
            img: EnImage
        },
    ]

    const localLang = localStorage.getItem("i18lang") ? langs.find(i => i.key === localStorage.getItem("i18lang")) : langs[0]
    const [selectedItem, setselectedItem] = useState(localLang)


    const changeLang = (event: any) => {
        localStorage.setItem("i18lang", event.key)
        setselectedItem(event)
        i18n.changeLanguage(event.key)
    }

    const menu = (
        <Menu>
            {
                langs.map((item) => (
                    <Menu.Item key={item.id} onClick={() => changeLang(item)} style={{ backgroundColor: theme.element, color: theme.text }}>
                        <img className="student_language_img" src={item?.img} alt="?" />
                        &nbsp;<span>{item.title}</span>
                    </Menu.Item>
                ))
            }
        </Menu>
    );
    return (
        <>
            <Dropdown overlay={menu} trigger={['click']} overlayStyle={{ backgroundColor: 'green' }}>
                <div className="student_language" style={{ backgroundColor: theme.element }}>
                    <img className="student_language_img" src={selectedItem?.img} alt="?" />&nbsp;
                    <span className="student_language_text" style={{ color: theme.text }}>{selectedItem?.title}</span>
                </div>
            </Dropdown>
        </>
    )
}


export default LanguageMobile;