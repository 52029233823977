import { Affix, BackTop, Button, Col, Collapse, Form, message, Result, Row, Spin, Tag, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import { CaretRightOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from "store/services";
import React, { useState } from "react";
import moment from "moment";
import { FaExclamationTriangle, FaUpload } from "react-icons/fa";
import { GetExamQuestion, getStudentAnswerForView } from "./service.requests";
import instance from "config/_axios";
import { useHistory, useRouteMatch, match, useLocation } from "react-router-dom";
import { editor_buttonList } from "./utils";
import { IExamQuestion } from "models/exam";
import ResultView from "./components/ResultView";
import _logout from "config/_axios/logout";
import ExamErrorPage from "./components/ExamErrorPage";
import "./styles.scss";
import { FILE_URL } from "config/utils";
import { changeExamQuestion } from "./functions.utils";
const { Panel } = Collapse;

const ExamView = (): JSX.Element => {

    const { t } = useTranslation();
    const [form] = Form.useForm();
    let isMobile = /iPhone|Android/i.test(navigator.userAgent);
    const match: match<any> | null = useRouteMatch("/exam/pass/:exam_id/:password");
    const dispatch: any = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const auth = useAppSelector(state => state.auth);

    const [checkSize, setCheckSize] = useState<boolean>(false)
    const [answerFile, setAnswerFile] = useState<any>();

    const [resultVisible, setResultVisible] = React.useState<boolean>(false);
    const [resultLoading, setResultLoading] = React.useState<boolean>(false);
    const [studentAnswer, setStudentAnswer] = React.useState<IExamQuestion>();




    // exam_questions -> exam question may be multiple. This variable stored tha exam questions.
    // time -> this time includes start, finish, duration and current time of student not exam.
    // exam_question -> this exam question by default includes 0 index value of exam questions. This can be changed to value in another index of exam questions if exam questions multiple.
    // question_count -> this variable means that exam questions multiple or not. If exam questions are multiple this variable includes ids of exam questions else will be empty.
    const { isLoading, exam_questions, time, exam_question, question_count } = useAppSelector(state => state.exam.pass_exam);
    const { isError, has_answer } = useAppSelector(state => state.exam.exam_errors);


    function generate_key(sub_question_id: number) {

        const user_id = auth.user.user_id;
        const exam_id = match?.params.exam_id;
        // data stored to localStorage by key. Key consists of user_id, exam_id, exam_question_id, sub_question_id and the composition will be in the same order like following.
        const key = `${user_id}_${exam_id}_${exam_question?.id}_${sub_question_id}`;

        const _isKey = !user_id || !exam_id || !exam_question || !sub_question_id

        return _isKey ? null : key
    }


    // write answer to localstorage by generated key and send answers to server getting from localstorage
    const handleChange = React.useCallback((sub_question_id: number, value: string) => {

        const key = generate_key(sub_question_id);

        if (key) {
            // setTimeout(() => onSubmit(sub_question_id), 60000);

            localStorage.setItem(key, value);
        } else {
            message.warning("Ma'lumot shakllantirishda xatolik!")
        }
    }, [exam_question])



    React.useEffect(() => {
        if (!exam_question) {
            dispatch(GetExamQuestion({ password: window.atob(match?.params.password), history, exam_id: match?.params.exam_id }))
        }
    }, [])


    function getStudentanswerForChecking() {
        if (exam_question) {
            // checkIsHasStudentAnswer(exam_question.id, generate_key, handleChange)
        }
    }

    // React.useEffect(() => {
    //     getStudentanswerForChecking()
    // }, [exam_question])


    React.useEffect(() => {
        if (location.pathname.includes('exam/pass')) {
            // window.onpopstate = function (event) {

            //     if (window.confirm("Sahifadan chiqmoqchimisiz?")) {
            //         history.goBack();
            //     } else {
            //         history.go(1);
            //     }

            // };
        }
    }, [])


    function clickViewAnswer(isRequest: boolean) {
        if (exam_question) {
            setResultVisible(prevState => !prevState);
            if (isRequest) {
                setResultLoading(true);
                getStudentAnswerForView(exam_question.id).then((response) => {
                    setStudentAnswer(response?.data)
                }).catch(() => {

                }).finally(() => {
                    setResultLoading(false);
                })
            }
        }
    }


    const getAnswerLocalStorage = (sub_question_id: number) => {

        const key = generate_key(sub_question_id);

        if (!key) return;

        const value = localStorage.getItem(key);

        if (!value) return

        return value

    }


    const onSubmit = async (_sub_question_id?: number) => {

        try {

            const formdata = new FormData();

            let answers: Array<{ sub_question_id: number, answer: string }> = []

            if (_sub_question_id) {
                const key = generate_key(_sub_question_id)
                if (key) {
                    const value = localStorage.getItem(key);
                    if (value) {
                        answers.push({
                            sub_question_id: _sub_question_id,
                            answer: value
                        })
                    }
                }
            } else {

                exam_question?.question.subQuestion.forEach((element) => {

                    const key = generate_key(element.id)

                    if (key) {
                        const value = localStorage.getItem(key);

                        if (value) {
                            answers.push({
                                sub_question_id: element.id,
                                answer: value
                            })
                        }
                    }

                })

            }

            if (answers.length) {
                formdata.append("subQuestionAnswers", JSON.stringify(answers));
                formdata.append("answer_file", answerFile);
                const response = await instance({ url: `exam-student-answers/${exam_question?.id}`, method: 'PUT', data: formdata });

                if (response.data.status === 1) {
                    if (!_sub_question_id) {
                        message.success("Imtihon javoblari saqlandi!")
                    }
                }
            } else {
                message.warning("Javob yozilmagan!")
            }


        } catch (error: any) {
            if (Array.isArray(error?.response?.data?.errors) && error.response.data.errors.length) {

                (error.response.data.errors as any[]).forEach((errorMessage) => {
                    if (typeof errorMessage !== "string" && typeof errorMessage !== 'number') {
                        if (!errorMessage.status) {
                            history.push(`/exam/info/${exam_question?.exam_id}`);
                        }
                    }
                })

            }
        }
    }





    if (isError) {

        return <ExamErrorPage getStudentanswerForChecking={getStudentanswerForChecking} />
    }

    if (isMobile) {

        return (
            <div className="px-3 py-3">
                <Card>
                    <CardBody className="mt-3" style={{ height: "86vh" }}>
                        <Result
                            status="warning"
                            title="Your device not supported !"
                            extra={
                                <Button type="primary" key="console" onClick={() => history.push('/')}>
                                    Asosiy sahifaga qaytish
                                </Button>
                            }
                        />
                    </CardBody>
                </Card>
            </div>
        )
    }

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }

    const checkFileSize = (e: any) => {
        if (e?.file?.size > 5242880 && e?.file?.status !== 'removed') {
            message.error(`Fayl hajmi 5 Mb dan oshmasligi kerak!!!`);
            setCheckSize(true);
        } if(e?.file?.status !== 'removed'){
            setCheckSize(false);
            setAnswerFile(e?.file?.originFileObj);
        } else {
            setCheckSize(false);
        }
    }

    return (
        <div translate="no" className="exam_write_page">
            <BackTop style={{ right: 10 }} />
            <Spin spinning={isLoading}>
                {
                    exam_question ?
                        <Row gutter={[12, 12]}>
                            {
                                resultVisible ?
                                    <Col xl={20}>
                                        <ResultView data={studentAnswer} isLoading={resultLoading} />
                                    </Col> :
                                    <Col xl={20}>
                                        <Card>
                                            <CardBody>
                                                <div className="d-f justify-content-between">
                                                    <strong>{exam_question.question_type}</strong>
                                                    <div>
                                                        {
                                                            question_count.length ?
                                                                question_count.map((element, index) => (
                                                                    <Button
                                                                        key={element}
                                                                        type={exam_question.id === element ? "primary" : "dashed"}
                                                                        size="small"
                                                                        className="ms-2"
                                                                        onClick={() => changeExamQuestion(element)}>{index + 1} - savol</Button>
                                                                )
                                                                ) : null
                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                                <div style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: exam_question?.question.question?.replaceAll("&nbsp;", " ").trim() ?? '' }} ></div>
                                                {exam_question.question.file ?
                                                    fileCheckFormat(exam_question.question.file) : null}
                                            </CardBody>
                                        </Card>
                                        <Form form={form}>
                                            <Collapse
                                                bordered={false}
                                                defaultActiveKey={['1']}
                                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                className="site-collapse-custom-collapse px-0"
                                            >
                                                <Panel
                                                    key={0}
                                                    header={
                                                        <div className="d-flex">Javobni (pdf) fayl shaklida yuklash</div>
                                                    }
                                                    className="site-collapse-custom-panel"
                                                >
                                                    <Form.Item name='upload_file' className="mb-2" >
                                                        <Upload
                                                            maxCount={1}
                                                            accept={"application/pdf"}
                                                            className="bg-dark"
                                                            customRequest={dummyRequest}
                                                            onChange={checkFileSize}
                                                        >
                                                            <Button icon={<FaUpload />} type="dashed" style={{ color: checkSize ? "#EA4335" : "", borderColor: checkSize ? "#EA4335" : "" }} className="mb- upload_btn bg-light">&nbsp; Upload</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Panel>
                                                {
                                                    exam_question.question.subQuestion.length ?
                                                        exam_question.question.subQuestion.map((subQuestion, index: number) => {
                                                            return (
                                                                <Panel
                                                                    key={index + 1}
                                                                    header={
                                                                        <div className="d-flex">
                                                                            <div className="exam_sub_question" dangerouslySetInnerHTML={{ __html: subQuestion.question?.replaceAll("&nbsp;", " ").trim() ?? '' }} ></div>
                                                                            &nbsp;&nbsp;<Tag color="#F3F5F7" style={{ width: "180px", height: '22px', fontWeight: 500, color: '#7B808B' }}>MAKSIMAL BALL: {exam_question?.max_ball * (subQuestion?.percent / 100)}</Tag>
                                                                        </div>
                                                                    }
                                                                    className="site-collapse-custom-panel"
                                                                >
                                                                    <Form.Item key={index + 1} name={`${exam_question.id}_${subQuestion.id}`} >
                                                                        <SunEditor
                                                                            onPaste={(e) => { e.preventDefault(); message.warning("Ko'chirish mumkin emas! Shpargalkadan foydalanish imtihondan chetlatilishga sabab bo'lishi mumkin!") }}
                                                                            onDrop={(e) => { e.preventDefault(); }}
                                                                            height={300 + "px"}
                                                                            placeholder={t("Enter an answer")}
                                                                            onChange={(value) => handleChange(subQuestion.id, value)}
                                                                            setContents={getAnswerLocalStorage(subQuestion.id)}
                                                                            setAllPlugins={true}
                                                                            lang="ru"
                                                                            setOptions={{
                                                                                fontSize: [12, 14, 16, 18, 20, 24, 32],
                                                                                paragraphStyles: ["dsfsd"],
                                                                                defaultStyle: 'fonst-size:22px',
                                                                                fontSizeUnit: "px",
                                                                                mathFontSize: [{ text: '18', value: '18', default: true }],
                                                                                // codeMirror: 'CodeMirror',
                                                                                katex: 'window.katex',
                                                                                buttonList: editor_buttonList,
                                                                                font: [
                                                                                    "Arial",
                                                                                    "Times New Roman",
                                                                                    "Helvetica",
                                                                                    "Calibri",
                                                                                    "Georgia",
                                                                                    "Impact",
                                                                                    "Tahoma",
                                                                                ],
                                                                            }} />
                                                                    </Form.Item>
                                                                </Panel>
                                                            )
                                                        }) : <span>Savollar topilmadi !</span>
                                                }
                                            </Collapse>
                                        </Form>
                                    </Col>
                            }
                            <Col xl={4}>
                                <Affix offsetTop={75}>
                                    <Card>
                                        <CardBody>
                                            <div>
                                                <div className="warning_exam_pass">
                                                    <strong><FaExclamationTriangle size={14} className="mb-1" />&nbsp;&nbsp;E'tibor qarating!</strong><br />
                                                    <span>
                                                        Topshirilgan imtihon bilan bog'liq muammoli vaziyat yuzaga kelsa imtihon xonasini tark etmasdan nazoratchilarga murojat qiling.
                                                        Imtihonni yakunlaganingizdan so'ng savollar uchun yozilgan barcha javoblaringizni tekshiring, barcha javoblaringiz mavjud bo'lsa yakunlash
                                                        tugmasini bosish orqali imtihonni yakunlash mumkin aks holda
                                                        imtihonni yakunlamasdan xona nazoratchilariga xabar bering.
                                                    </span>
                                                </div>
                                                <div className="exam_pass_info">
                                                    <p>IMTIHON MA'LUMOTLARI</p>
                                                    <div className="date_box_exam_pass">
                                                        <span>fan: </span><b>{exam_question.exam?.subject?.name}</b><br />
                                                        <span>savol turi: </span><b>{exam_question.question_type}</b><br />
                                                        <span>savollar soni: </span><b>{Object.keys(JSON.parse(exam_question.exam?.question_count_by_type_with_ball ?? "{}")).length}</b><br />
                                                        <span>maksimal ball: </span><b>{exam_question.max_ball}</b><br />
                                                        <span>boshlash: </span><b>{moment(time?.start).format("DD-MM-YYYY HH:mm:ss")}</b><br />
                                                        <span>tugatish: </span><b>{moment(time?.finish).format("DD-MM-YYYY HH:mm:ss")}</b><br />
                                                        <span>davomiyligi: </span><b>{moment.utc(Number(time?.duration) * 1000).format("HH:mm:ss")}</b>
                                                    </div>
                                                </div>
                                                <Button type="primary" className="w-100  mt-3" onClick={() => onSubmit()}>Javobni saqlash</Button>
                                                <Button type="primary" className="w-100  mt-5" danger={!resultVisible} onClick={() => clickViewAnswer(!resultVisible)}>
                                                    {resultVisible ? "DAVOM ETTIRISH" : "SAQLANGAN JAVOBLAR"}
                                                </Button>
                                                {/* <Popconfirm
                                                    placement="top"
                                                    title={<span className="w-100 d-block" >Imtihonni yakunlash</span>}
                                                    onConfirm={() => _logout()}
                                                    onCancel={() => setResultVisible(false)}
                                                    okText="YAKUNLASH"
                                                    cancelText="DAVOM ETISH"
                                                    className="w-100"
                                                    style={{ width: "100%" }}
                                                >
                                                    <Button type="primary" className="w-100  mt-5" danger>Imtihonni yakunlash</Button>
                                                </Popconfirm> */}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Affix>
                            </Col>
                        </Row> :
                        null
                }
            </Spin>
        </div >
    )
}


export function fileCheckFormat(file: string) {

    if (file.endsWith(".pdf")) {
        return (
            <iframe src={FILE_URL + file} width="100%" height={'800px'}></iframe>
        )
    } else if (file.endsWith(".docx") || file.endsWith('.doc' || file.endsWith('.ppt'))) {
        return (
            <div>File mavjud va format xatoligi sababli ochishning imkoni yo'q</div>
        )
    } else {
        return (
            <div className='question_img_box'>
                <img src={FILE_URL + file} alt="" />
            </div>
        )
    }
}

export default ExamView;